// 字典 查询
import request from '../utils/request'


export function dictList(query) {
  return request({
    url: 'system/dict/data/list',
    method: 'get',
    params: query
  })
}

export const dictsFilter = (dictsType, val) => {
  if (JSON.stringify(dictObj.value) == "{}") return;
  const dict = dictObj.value;

  const valObj = dict[dictsType].filter((item) => item.dictValue == val)[0];
  return (valObj && valObj.dictLabel) || "";
};