<script setup>
import { onMounted } from 'vue';
import HelloWorld from './components/HelloWorld.vue'
onMounted(() => {
  bodyScale()

});


const bodyScale = () => {
      var devicewidth = document.documentElement.clientWidth;//获取当前分辨率下的可是区域宽度
      var scale = devicewidth / 1500; // 分母——设计稿的尺寸
      document.body.style.zoom = scale;//放大缩小相应倍数
}
</script>

<template>
  <router-view></router-view>
  
</template>

<style >
 .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #67c23a !important;
}
  .el-tabs__item:not(.is-disabled):hover {
    color: #67c23a !important;
}
 .el-input__wrapper.is-focus {
  box-shadow: 0px 0px 0 1px #95d475 inset;
}
.cancel-button-class,.confirm-button-class{
  padding: 25px !important;
  font-size: 25px !important;
}
.custom-class-text .el-message-box__title{
  font-size: 30px;
}
.custom-class-text  .el-message-box__message{
  font-size: 25px;
}
.flex {
  display: flex;
}
.flexItems {
  display: flex;
  align-items: center;
}
.justifyBetween {
  justify-content: space-between;
}
.listImg{
  border-radius: 15px;
}
.flexWrap {
  flex-wrap: wrap;
}
</style>
