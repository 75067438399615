export let GetNumByUnit = function (num = 0, unitname, outunitname, isTofix = false) {
    var fRate = {//换算率
        ng: { μg: 0.001, mg: 0.001 * 0.001, g: 0.001 * 0.001 * 0.001, kg: 0.001 * 0.001 * 0.001 * 0.001, t: 0.001 * 0.001 * 0.001 * 0.001 * 0.001, ul: 0.001 * 0.001, ml: 0.001 * 0.001 * 0.001, L: 0.001 * 0.001 * 0.001 * 0.001 },
        μg: { ng: 1000, mg: 0.001, g: 0.001 * 0.001, kg: 0.001 * 0.001 * 0.001, t: 0.001 * 0.001 * 0.001 * 0.001, ul: 0.001, ml: 0.001 * 0.001, L: 0.001 * 0.001 * 0.001 },
        mg: { ng: 1000 * 1000, μg: 1000, g: 0.001, kg: 0.001 * 0.001, t: 0.001 * 0.001 * 0.001, ul: 1, ml: 0.001, L: 0.001 * 0.001 },
        g: { ng: 1000 * 1000 * 1000, μg: 1000 * 1000, mg: 1000,g:1, kg: 0.001, t: 0.001 * 0.001, ul: 1000, ml: 1, L: 0.001,j:0.002 },
        j: {  g: 500,  kg: 0.5 ,j:1 },
        kg: { ng: 1000 * 1000 * 1000 * 1000, μg: 1000 * 1000, mg: 1000, g: 1000,kg:1, t: 0.001, ul: 1000 * 1000, ml: 1000, L: 1 ,j:2 },
        t: { ng: 1000 * 1000 * 1000 * 1000 * 1000, μg: 1000 * 1000 * 1000, mg: 1000 * 1000, g: 1000 * 1000, kg: 1000, ul: 1000 * 1000 * 1000, ml: 1000 * 1000, L: 1000 },
        ml: { ng: 1000 * 1000 * 1000, μg: 1000 * 1000, mg: 1000, g: 1, kg: 0.001, t: 0.001 * 0.001, ul: 1000, L: 0.001 },
        ul: { ng: 1000 * 1000, μg: 1000, ml: 1, g: 0.001, kg: 0.001 * 0.001, t: 0.001 * 0.001 * 0.001, ml: 0.001, L: 0.001 * 0.001 },
        L: { ng: 1000 * 1000 * 1000 * 1000, μg: 1000 * 1000, mg: 1000, g: 1000, kg: 1, t: 0.001, ul: 1000 * 1000, ml: 1000 },
    };
    // var tnum = (num * fRate[unitname][outunitname]).toFixed(4);
    var tnum = (num * fRate[unitname][outunitname]);
    let tnums = toFixedDigit(tnum, 3)
    // console.log(tnums,'234');
    // return tnum;
    if (isTofix) {
        return tnum
    }
    return tnums;
};

export function toFixedDigit(num, n) {//保留n位
    if (typeof num != 'number') {
        return false;
    };
    if (n <= 0) {//不保留小数，取整
        return num | 0;
    };
    num = num.toString();
    var result = "";
    var zeroResult = function (n) {
        var zero = "";
        for (var i = 0; i < n; i++) {
            zero += "0";
        }
        return zero;
    }
    if (num % 1 == 0) {//整数
        result = num + "." + zeroResult(n);
    } else {//小数
        var num1 = num.split(".");
        if (num1[1].length < n) {
            result = num1[0] + "." + num1[1] + zeroResult(n - num1[1].length)
        } else {
            result = num1[0] + "." + num1[1].substring(0, n)
        }
    }


    return Number(result);
}
// var result1 = toFixedDigit(8.7,2)
// console.log(result1)//8.70
// var result2 = toFixedDigit(8,2)
// console.log(result2)//8.00
// var result3 = toFixedDigit(8.6999,2)
// console.log(result3)//8.69
// var result4 = toFixedDigit(85.6999765,4)
// console.log(result4)//85.6999
