<script setup>
import {
  getCurrentInstance,
  nextTick,
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
} from "vue";

import { accMul, accSub, accDiv } from "../../utils/count";
import { getBase64 } from "../../utils/fie";
import {
  getNowFormatDate,
  getTomorrowDate,
  parseTime,
  getDaysBetween,
  getYesterdayDate,
} from "../../utils/time";
import { dicts } from "../../utils/getDict";
import { useRouter } from "vue-router";
import TakePhotoImg from "../../components/TakePhotoImg.vue";
import { uploadStaffPic } from "./dataURLtoFile.js";
import {
  warehouseRecordlist,
  qrCode,
  addscale,
  warehouseScalelist,
} from "../../api/addMaterial.js";
import { GetNumByUnit, toFixedDigit } from "../../utils/uni.js";
import { initScale } from "../../api/outMaterial.js";

defineProps({
  msg: String,
});
const router = useRouter();
let PhotoImg = ref();
const { proxy } = getCurrentInstance();
let activeName = ref(0);
// 获取头部
let headerRef;

let tabList = ref([]);
const count = ref(0);
const dictObj = ref({});
var purchaseOrderList = ref([]);
let setTiem = ref(null);
let range = ref(0);
let offsetState = ref("");
// 计重单位字典表的value
let weightUntList = ref(["0", "1", "2", "13", "14"]);

var formData = reactive({
  purchaseOrder: 1,
  procureW: "100",
  supplier: "四川夸克餐饮管理有限公司",
  weigth: null,
  pName: "空心菜",
});
const date = new Date();
let selectMenc = ref(1);
let params = ref({});

let warehouseUrl = "";
onMounted(async () => {
  await dictsFun();
  qrCodeFun();
  getInitScale();
  // getList();
  menuClick(1);

  getWeight();
});

// 卸载之前
onBeforeUnmount(() => {
  console.log("卸载之前");
  clearInterval(setTiem);
});
//获取字典值
const dictsFun = async () => {
  dictObj.value = dicts(["unit_type", "goods_type", "no_unit"], dictObj.value);
};
// 今日菜单、昨日菜单
const menuClick = (type) => {
  selectMenc.value = type;

  const date = new Date();
  // 昨日
  const yesterday = parseTime(
    getNowFormatDate(getYesterdayDate(date)),
    "{y}-{m}-{d}"
  );
  // 近7天
  const sevenday = parseTime(
    getNowFormatDate(getYesterdayDate(date, 7)),
    "{y}-{m}-{d}"
  );
  // 今日
  const day = parseTime(date, "{y}-{m}-{d}");
  const beginTime = type == 1 ? day : sevenday;
  // const beginTime = type == 1 ? day : yesterday;
  const endTime = type == 1 ? day : yesterday;
  // const endTime = type == 1 ? day : yesterday;
  params.value = {
    pageNum: 1,
    pageSize: 2000,
    params: {
      beginTime: beginTime + " 00:00:00",
      endTime: endTime + " 23:59:59",
    },
    status: 0,
  };
  getList();
};

// 获取二维码图片
const qrCodeFun = async () => {
  qrCode().then((res) => {
    warehouseUrl = res;
  });
};
// 加工称初始化
const getInitScale = () => {
  initScale().then((res) => {
    range.value = res.data.offset;
    offsetState.value = res.data.offsetState;
  });
};
// 获取列表
let supplierList = [];

const getList = (str = 0) => {
  warehouseScalelist(params.value)
    .then((res) => {
      const list = res.rows || [];
      const supplierTabList = list.filter(
        (item, index) =>
          list.findIndex((o) => o.supplierId == item.supplierId) == index
      );
      tabList.value = supplierTabList;
      supplierList = childrenFun(res.rows);
      tabClick(str);

      return;
      supplierList = dataFile(res.rows) || [];
    })
    .catch((err) => {
      tabList.value = [];
    });
};

const childrenFun = (data) => {
  return data.filter((item) => {
    if (item.list) {
      item.list = childrenFun(item.list);
    }
    item.isFig =
      item.addWeight && item.addImage && item.scaleStatus == 1 ? true : false;
    if (item.addAllprice ) {
      const addWeight = GetNumByUnit(item.orderNo, "g", "kg");
      const practicalWeight = GetNumByUnit(item.addWeight, "g", "kg");
      let weight = practicalWeight ? practicalWeight : addWeight;
      const addPrice = accDiv(item.addAllprice, weight);
      // const addPrice=accDiv(item.addAllprice,addWeight)
      console.log(weight,addPrice,'addPrice');
      item.kgUnitAddPrice = toFixedDigit(item.kgUnitAddPrice, 2);
      // item.addPrices = toFixedDigit(addPrice, 2);
    }
    // if (item.unit != 5 && item.sizeUnit != 5) return item;
    return item;
  });
};

const dataFile = (data) => {
  data.forEach((item) => {
    item.warehouseAddRecordInfoList = item.warehouseAddRecordInfoList || [];
    item.warehouseAddRecordInfoList =
      item.warehouseAddRecordInfoList.filter((v) => {
        const dictsVal = dictsFilter("goods_type", v.goodsType);
        // return   dictsVal !== "调味类" && v.unit != 5 && v.unit != 3 && v.unit != 4
        v.isFig =
          v.addWeight && v.addImage && v.scaleStatus == 1 ? true : false;
        return v.unit != 5 && v.sizeUnit != 5;
      }) || [];
  });

  let dataArr =
    data.filter(
      (item) =>
        item.warehouseAddRecordInfoList &&
        item.warehouseAddRecordInfoList.length !== 0
    ) || [];
  return dataArr;
};
// 字典值过滤当前值
const dictsFilter = (dictsType, val, isLable) => {
  if (JSON.stringify(dictObj.value) == "{}") return;
  const dict = dictObj.value;
  let valObj = {};
  if (isLable) {
    valObj = dict[dictsType].filter((item) => item.dictLabel == val)[0];
    return (valObj && valObj.dictValue) || "";
  } else {
    valObj = dict[dictsType].filter((item) => item.dictValue == val)[0];
    return (valObj && valObj.dictLabel) || "";
  }
};
// 返回首页
function closeBtnCLick() {
  router.push("/");
}
// 点击tab 事件
const tabClick = (index) => {
  activeName.value = index;
  // 获取点击的供应商id
  const supplierId = tabList.value[index].supplierId;
  // 过滤出对应的商品
  purchaseOrderList.value = supplierList.filter(
    (item) => item.supplierId == supplierId
  );
};

const tabClick1 = (index) => {
  activeName.value = index;
  return;
  purchaseOrderList.value = [];
  const tabItem = tabList.value[index];
  tabItem.warehouseAddRecordInfoList[0].shadow = "always";
  // warehouseUrl += tabItem.warehouseAddRecordInfoList[0].addInfoId;
  if (tabItem.warehouseAddRecordInfoList.length) {
    tabItem.warehouseAddRecordInfoList.forEach((item) => {
      item.isFig =
        item.practicalWeight && item.addImage && item.scaleStatus == 1
          ? true
          : false;
    });
  }
  console.log(
    tabItem.warehouseAddRecordInfoList,
    "=tabItem.warehouseAddRecordInfoList"
  );
  purchaseOrderList.value = tabItem.warehouseAddRecordInfoList || [];
};

// 选择当前需要秤重数据
const cardClick = (row, index) => {
  if (row.addWeight && row.addImage && row.scaleStatus == 1) return;
  purchaseOrderList.value.forEach((o) => {
    o.list.forEach((ite) => {
      ite.list.forEach((item) => {
        item.shadow = item.addInfoId == row.addInfoId ? "always" : "";
        if (item.addInfoId == row.addInfoId) {
          item.addWeight = formData.weigth || 0;
          // const allPrice = accMul(item.addPrice, item.practicalWeight).toFixed(1);
          // item.addAllprice = Number(allPrice);
          numberChange(item.kgUnitAddPrice, row);
        }
      });
    });
  });
};
// 单价change事件
let currenSelectionRow = ref({});
const numberChange = (e, row, pro) => {
  currenSelectionRow.value = row;
  if (pro) {
    row.kgUnitAddPrice = e;
  }
  if (row.addWeight && row.addImage && row.scaleStatus == 1) return;

  // row.practicalWeight = formData.weigth || 0;
  const practicalnum = formData.weigth;
  //   row.unit == 3 || row.unit == 4
  //     ? row.addOrderNo
  //     : GetNumByUnit(formData.weigth, "kg", "j");
  const allPrice = e ? toFixedDigit(accMul(row.kgUnitAddPrice, practicalnum), 2) : 0;
  row.addAllprice = Number(allPrice);
};

// 重量改变事件
const weightSelection = (res) => {
  const row = currenSelectionRow.value;
  if (row.addWeight && row.addImage && row.scaleStatus == 1) return;

  if (JSON.stringify(row) !== "{}") {
    purchaseOrderList.value.forEach((o) => {
      o.list.forEach((ite) => {
        ite.list.forEach((item) => {
          if (row.addInfoId == item.addInfoId) {
            item.addWeight = formData.weigth || 0;
            numberChange(row.kgUnitAddPrice, row);
          }
        });
      });
    });
  }
};
// 拍照确认照片
const photoClick = async (data = {}, row) => {
  console.log(data, "23423");
  row.loading = true;
  row.addImage = (data && data.url) || "";
  row.loading = false;

  // row.addImage = data.imgUrl;
  // row.personnelUrl = data.personnelUrl;
};
// 清空拍照图片
const photoColse = (row) => {
  if (row.addWeight && row.addImage && row.scaleStatus == 1) return;
  purchaseOrderList.value.forEach((o) => {
    o.list.forEach((ite) => {
      ite.list.forEach((item) => {
        if (item.addInfoId == row.addInfoId) {
          item.addImage = "";
        }
      });
    });
  });
};
// 入仓
const warehousing = (row, i) => {
  const practicalWeight = GetNumByUnit(row.addWeight, "kg", "g");
  // if(row.addImage){
  //   console.log(222);
  // }
  row.butLoad = true;

  // 拍照
  if (!row.addImage) {
    automaticPhoto(row, i);
    return;
  }

  if (row.addWeight && row.addImage && row.kgUnitAddPrice) {
    //判断重量及图片、价格不为空
    // 必须判断偏离值
    if (offsetState.value == "1") {
      getRange(row, i);
    } else {
      //不需要判断偏离值
      addWarehousing(row, i);
      // if (row.addWeight <= practicalWeight) {
      //   addWarehousing(row);
      // } else {
      //   let message = "少了！！！";
      //   proxy.$message.warning(message);
      // }
    }
  } else {
    row.butLoad = false;
    proxy.$message.warning(
      "请检查重量(重量不能为0)、单价(单价不能为0)及图片是否完善"
    );
  }
};
// 自动调用拍照
const automaticPhoto = async (row, i) => {
  row.butLoad = false;

  if (!proxy.$refs.PhotoImg) return (row.butLoad = false);
  // 获取当前确定自动拍照数据的下标
  const index = proxy.$refs.PhotoImg.findIndex((o) => {
    let item = o.row;
    return (
      item.supplierCcCode == row.supplierCcCode &&
      item.goodsId == row.goodsId &&
      item.canteenGoodsSpecId == row.canteenGoodsSpecId &&
      item.scaleStatus == row.scaleStatus
    );
  });
  if (!proxy.$refs.PhotoImg[index]) return (row.butLoad = false);

  const data = (await proxy.$refs.PhotoImg[index].photograph(1)) || {};
  // row.isFig=true
  setTimeout(() => {
    row.addImage = data.url || "";
    if (!row.addImage) return;
    warehousing(row, index);
  }, 200);
};

// 启用绝对值进行比较
const getRange = (row, i) => {
  const practicalWeight = GetNumByUnit(row.addWeight, "kg", "g");
  // 取 偏离值的绝对值
  const num = accSub(row.addWeight, practicalWeight);
  const numbe = range.value * -1;
  if (num >= numbe && num <= range.value) {
    addWarehousing(row, i);
  } else {
    let message =
      num < range.value ? "多了！！！" : num > numbe ? "少了！！！" : "";
    proxy.$message.warning(message);
    row.butLoad = false;
  }
};

// 入仓请求
const addWarehousing = (row, i) => {
  let beginTime = parseTime(getNowFormatDate(date), "{y}-{m}-{d}");
  const time =
    row.goodsType == 1 ? (row.producedTime ? row.producedTime : beginTime) : "";
  proxy.$messageBox
    .confirm(`是否确认入库?`, "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      showClose: false,
      type: "warning",
      buttonSize: "large",
      cancelButtonClass: "cancel-button-class",
      customClass: "custom-class-text",
      confirmButtonClass: "confirm-button-class el-button--success",
      // confirmButtonClass: "el-button el-button--success el-button--large",
    })
    .then(() => {
      const userId = localStorage.getItem("userId");
      const addWeight = GetNumByUnit(row.addWeight, "kg", "g");
      const minimumUnitAddPrice = row.addAllprice / addWeight;

      const obj = {
        signer: Number(userId),
        scaleStatus: "1",
        // practicalUnit: "0",
        practicalUnit: row.unit,
        // unit: "0",
        // minimumUnitAddPrice,
        addWeight: addWeight,
        // practicalWeight: practicalWeight,
        producedTime: time,
      };

      // PhotoImg.value[i].photograph();
      const data = Object.assign({}, row, obj);
      addscale(data)
        .then((res) => {
          if (res.code == 200) {
            row.isFig = true;
            proxy.$message.success(res.msg);
            const active = activeName.value;
            row.addWeight=obj.addWeight;
            row.shadow = '';
            row.scaleStatus = "1";
            // getList(active);
            // print(row);
          } else {
            row.isFig = false;
            row.scaleStatus = "0";
            proxy.$message.error(res.msg);
          }

          row.butLoad = false;
        })
        .catch((err) => {
          row.scaleStatus = "0";
          row.isFig = false;
          // proxy.$error(res.msg);
          row.butLoad = false;
        });
    })
    .catch(() => {
      proxy.$message("取消");
      row.butLoad = false;
    });
};

// 获取数字
const getFloat = function (v) {
  if (typeof v == "string") {
    const str = v.split(/[\t\r\f\n\s]*/g).join("");
    var regEx = /[^\d|^\.|^\-]/g;
    const num = str.replace(regEx, "");
    return parseFloat(num);
  }
};
// 每200 刷新秤端值
const getWeight = () => {
  setTiem = setInterval(() => {
    const weight = getFloat(script.getWindowName());
    if (formData.weigth !== weight) {
      formData.weigth = weight;
      weightSelection(weight);
    }
  }, 200);
  return setTiem;
};
//打印
const print = (row) => {
  const codeUrl = `${warehouseUrl}warehouseId=${row.warehouseId}&addInfoId=${row.addInfoId}`;
  const goodsType = dictsFilter("goods_type", row.goodsType);
  const day =
    row.producedTime && row.guaranteePeriod
      ? getDaysBetween(row.producedTime, row.guaranteePeriod) + "天"
      : " ";

  const dat = script.printPages(
    codeUrl || " ",
    row.goodsName || " ",
    goodsType || " ",
    parseTime(row.createTime, "{y}-{m}-{d}") || " ",
    parseTime(row.producedTime, "{y}-{m}-{d}") || " ",
    parseTime(row.guaranteePeriod, "{y}-{m}-{d}") || " ",
    day || " "
  );

};

// 字典值
const dictLable = (dict, dictValue = "") => {
  const data = dictObj.value;
  let dictVal = null;
  if (data[dict]) {
    dictVal = data[dict].filter((item) => item.dictValue === dictValue)[0];
  }
  return (dictVal && dictVal.dictLabel) || "";
};

// 商品数量和计数单位拼接
const goodesUnit = (row) => {
  let unitType =
    row.noUnit == 10
      ? "j"
      : row.noUnit == 11 || row.noUnit == 13
      ? "g"
      : row.noUnit == 12
      ? "L"
      : row.noUnit == 14
      ? "kg"
      : "";

  let val = row.cubage;
  if (unitType) {
    val = GetNumByUnit(val, "g", unitType);
  }
  console.log(row, "24234");
  const noUnit = dictLable("no_unit", row.noUnit);

};

</script>

<template>
  <div class="scale-cont">
    <div class="header" ref="headerRef">
      <div class="flexItems titleBox cont">
        <div class="flex title-box">
          <h1
            class="title-menu"
            :class="selectMenc == 1 ? 'fontSize' : ''"
            @click="menuClick(1)"
          >
            今日入仓
          </h1>
          <h1
            class="title-menu"
            :class="selectMenc == 2 ? 'fontSize' : ''"
            @click="menuClick(2)"
          >
            昨日入仓
          </h1>
          <!-- <el-button type="success" round size="default">今日菜单</el-button> -->
        </div>
        <el-button
          type="success"
          class="confirm-button-class"
          size="large"
          @click="closeBtnCLick"
          >返回</el-button
        >
        <h1 class="title">仓库入库</h1>
      </div>
      <div class="flex tabList cont" v-if="tabList.length">
        <div
          v-for="(item, index) in tabList"
          :class="activeName == index ? 'tabSelect' : ''"
          @click="tabClick(index)"
          class="tabItem"
          :key="item.id"
        >
          <div>{{ item.supplierName }}</div>
          <!-- <div>{{ item.invoicesCode }}</div> -->
        </div>
      </div>
    </div>
    <!-- 定位空间 -->
    <!-- <div class="headerH"></div> -->
    <div class="infinite-list" style="overflow: auto">
      <div class="cont" v-if="tabList.length">
        <div
          v-for="(item, index) in purchaseOrderList"
          :key="index"
          style="padding-bottom: 10px"
        >
          <div class="contTitle">{{ item.supplierRcCode }}</div>
          <el-divider style="margin: 10px 0" />
          <template v-for="(ite, ind) in item.list" :key="ind">
            <div class="title contWeight">{{ ite.name }}</div>
            <template v-for="(v, i) in ite.list" :key="i">
              <el-card
                :shadow="v.shadow || ''"
                class="tradeList"
                @click="cardClick(v, index, ind)"
              >
                <div class="flex">
                  <img
                    class="listImg"
                    :src="
                      v.goodsImg ||
                      'https://img0.baidu.com/it/u=2404799835,4177816153&fm=253&fmt=auto&app=138&f=JPEG?w=515&h=500'
                    "
                    alt="暂无图片"
                  />
                  <div class="trader">
                    <div class="tradeCont">
                      <span class="tradeName">{{ v.goodsName }}</span>
                      <!-- <span class="tradeName"
                        >{{ GetNumByUnit(v.addWeight, "g", "kg") }}kg
                      </span>
                      <span class="tradeName">({{ v.addWeight || 0 }}g)</span> -->
                      <span class="tradeName"
                        >{{ GetNumByUnit(v.orderNo, "g", "kg") }}kg
                      </span>
                      <span class="tradeName">({{ v.orderNo || 0 }}g)</span>

                      <!-- <span class="tradeName">{{
                  `(${v.orderNo}${dictLable("unit_type", v.unit)})`
                }}</span> -->

                      <span class="tradeName" style="margin-left: 30px"
                        >实际重量</span
                      >
                      <span class="tradeName">
                        <!-- {{
                          v.isFig
                            ? GetNumByUnit(v.practicalWeight, "g", "kg")
                            : v.practicalWeight || 0
                        }}kg -->
                        {{
                          v.isFig
                            ? GetNumByUnit(v.addWeight, "g", "kg")
                            : v.addWeight || 0
                        }}kg

                   </span>
                    </div>
                    <div
                      class="tradeName"
                      style="text-indent: 15px; height: 23px"
                    >

                      {{
                        v.specFullName && !weightUntList.includes(v.unit)
                          ? `(${v.specFullName})`
                          : ""
                      }}
                    </div>

                    <div class="tradeCont flexItems" style="margin-top: 20px">
                      <span class="tradePrice">单价</span>
                      <el-input-number
                        :min="0"
                        :disabled="v.isFig"
                        size="large"
                        :step="0.1"
                        @change="numberChange($event, v, 'kgUnitAddPrice')"
                        v-model="v.kgUnitAddPrice"
                        class="mx-4"
                      />

                      <span>元/kg</span>
                      <div style="margin-left: 20px">
                        <span class="tradePrice">总价</span>
                        <span class="tradePrice"
                          >{{ v.addAllprice || 0 }}元</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="flex">
                    <div>照片：</div>
                    <TakePhotoImg
                      ref="PhotoImg"
                      :row="v"
                      @photoImgClick="photoClick($event, v)"
                      @photoColse="photoColse(v)"
                    ></TakePhotoImg>
                    <div>
                      <el-button
                        :disabled="v.isFig || v.butLoad"
                        :type="v.isFig ? 'info' : 'success'"
                        @click="warehousing(v, i)"
                        size="large"
                        style="
                          margin-left: 20px;
                          font-size: 30px;
                          padding: 30px;
                        "
                        >{{ v.isFig ? "已交货" : "入仓" }}</el-button
                      >

                      <el-button
                        v-if="v.isFig"
                        type="success"
                        @click="print(v, i)"
                        size="large"
                        style="
                          margin-top: 10px;
                          margin-left: 20px;
                          font-size: 30px;
                          display: block;
                          padding: 20px;
                          height: 45%;
                        "
                        >打印溯源码</el-button
                      >
                    </div>
                  </div>
                </div>
              </el-card>
            </template>
          </template>
        </div>
      </div>
      <el-empty description="暂无数据" v-else :image-size="200"></el-empty>
    </div>
  </div>
</template>

<style scoped>
.title-box {
  background: rgb(238, 238, 239);
  border-radius: 21px;
  /* margin-top: 5px; */
  margin-right: 10px;
  color: rgb(96, 98, 102);
}
.title-menu {
  text-align: left;
  font-size: 30px;
  padding: 14px 20px;
  cursor: pointer;
}
.fontSize {
  color: #ffff;
  background: #67c23a;
  /* padding: 10px; */
  /* line-height: 48px; */
  border-radius: 21px;
  font-weight: bold;
}

>>> .el-card.is-always-shadow {
  box-shadow: 0px -5px 25px rgba(0.5, 0, 0, 0.5);
}
.scale-cont {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.infinite-list {
  /* height: 300px; */
  padding: 0;
  margin: 0;
  padding-top: 15px;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: var(--el-color-primary-light-9);
  margin: 10px;
  color: var(--el-color-primary);
}

.header {
  /* position: fixed; */
  background: #fff;
  z-index: 999;
  width: 100%;
}
.headerH {
  height: 160px;
}
.tabList {
  border: 1px solid #e4e7ed;
  overflow-x: auto;
}
.tabItem {
  border-right: 1px solid #e4e7ed;
  padding: 10px 6px;
  min-width: 20%;
  min-height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 25px;
  font-weight: bold;
  color: #424141;
  /* transform: translateY(10px); */
  border-radius: none;
}
.tabSelect {
  background: #95d475;
  border-right-color: #95d475;
  color: #fff;
}
>>> .el-input__wrapper.is-focus {
  box-shadow: 0px 0px 0 1px #95d475 inset;
}
>>> .el-input-number__decrease:hover,
>>> .el-input-number__increase:hover {
  color: #95d475;
}
.cont {
  position: relative;
}
/* 屏幕小于1280px */
@media screen and (max-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/*  屏幕小于1520或大于1280 */
@media screen and (max-width: 1520px) and (min-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/* 屏幕大于1520px */
@media screen and (min-width: 1520px) {
  .cont {
    width: 75%;
    margin: 0 auto;
  }
}
.tradeList {
  margin: 6px 0 10px;
  border-radius: 20px;
}
.trader {
  flex: 1;
  /* text-align: center; */
}
.tabBox {
  padding: 0 30px;
  display: flex;
  /* width: 60%; */
}
.tradeName {
  font-size: 23px;
  margin: 0 5px;
  font-weight: bold;
}
.tradePrice {
  margin: 0 5px;
}
.tradeCont {
  margin: 10px 15px;
}
.listImg {
  width: 220px;
  height: 150px;
}
.el-tabs--border-card {
  width: 100%;
  margin-right: 15px;
}
.flex {
  display: flex;
}
.flexItems {
  display: flex;
  align-items: center;
}
.wrap {
  /* position: fixed; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.titleBox {
  margin-left: 2px;
  margin: auto;
}
.title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
  text-indent: 10px;
}
.contTitle {
  font-size: 25px;
  text-indent: 20px;
}
.contWeight {
  padding-top: 0;
}
</style>