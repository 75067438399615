<template>
  <div class="dialog">
    <div class="content">
      <div class="outLog">
        <el-icon>
          <refresh-right style="width: 3em; height: 1em; margin-right: -14px" />
        </el-icon>
        <div style="font-weight: bold" @click="outLogin">退出账号</div>
      </div>
      <div class="flex">
        <div class="into contentBtn" @click="outClick">
          <img src="../assets/u6.png" />
          <div class="dialogText">出餐</div>
        </div>
        <div class="out contentBtn" @click="intoClick">
          <img src="../assets/u10.png" />
          <div class="dialogText">回餐</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  computed,
  onMounted,
  watch,
} from "vue";
import { setToken } from "../utils/auth";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "returnMal",
  setup() {
    const router = useRouter();
    const state = reactive({

    });
    const intoClick = () => {
      router.push("/quitMeal");

    };
    const outClick = () => {
      router.push("/scaleReduce");

    };
    const outLogin = () => {
      router.push({
    path: "Clock",
    state: { params: { type: "1", isScale: false } },
  });
    };

    return {
      ...toRefs(state),
      outClick,
      outLogin,
      intoClick,
    };
  },
});
</script>

<style scoped>
/deep/ .el-icon {
  width: 2em;
}
.outLog {
  font-size: 20px;
  text-align: right;

  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialogText {
  margin-top: 30px;
}
.read-the-docs {
  color: #888;
}
.materialWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.materialItem {
  width: 33vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.materialItem img {
  width: 25vw;
  height: 25vw;
  display: block;
}
.materialItem .materialName {
  text-align: center;
  padding: 12px 0 30px;
  font-size: 40px;
  font-weight: bold;
}

.dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
.dialogBg {
  width: 100%;
  height: 100%;
  /* background: #00000091; */
}

.dialog .content {
  width: 85vw;
  height: 40vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
}
.flex {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.content .contentBtn {
  width: 50%;
  height: 100%;
  background: #64b9ff;
  background: rgba(153, 204, 255, 1);
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 90px;
}
.content .out {
  background: #55a77e;
  background: rgba(153, 255, 204, 1);
}
</style>
