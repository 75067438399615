import request from '../utils/request'
// 出餐列表
export function listReduceRecord(query) {
    return request({
        url: '/system/reduceRecord/list',
        method: 'get',
        params: query
    })
}

//   出餐修改
export function editReduceRecord(param = {}) {
    return request({
        url: '/system/reduceRecord',
        method: 'put',
        data: param,
    })
}

//Scale/initScale  
 
export function initScale(param = {}) {
    return request({
        url: '/system/Scale/initScale',
        method: 'get',
        data: param,
    })
}