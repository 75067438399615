<script setup>
import { reactive, ref, onMounted } from "vue";

import { init, init_, weightAddListenerWeight } from "../utils/weight";
import { getHomeList } from "../api/requestUtil";

import { useRoute, useRouter } from "vue-router";

import { initCamera } from "../utils/TakePhotoUtil";

// console.log("import.meta.env", import.meta.env)

const router = useRouter();

var mList = reactive([
  "./home/4.jpg",
  "/home/4.jpg",
  "/home/4.jpg",
  "/home/4.jpg",
  "/home/4.jpg",
  "/home/4.jpg",
  "/home/4.jpg",
]);
var dialogVisible = ref(true);

const count = ref(0);
var socket;
var kg = ref(0);

var isInitWeigth = false;
var isScale = ref(false);
onMounted(() => {
  console.log(history.state, "history.state");
});
function itemClick() {
  // weightAddListenerWeight((res)=>{
  //     console.log("重量", res)
  //     kg.value = res
  // })
  if (!isInitWeigth) {
    isInitWeigth = true;
    weightAddListenerWeight((res) => {
      // console.log("重量", res)
      kg.value = res;
    });
    // initCamera((res)=>{

    // })
  }

  dialogVisible.value = true;
}

function ddddd() {
  init();
}

function addCLick() {
  addVisible.value = true;
}

//入仓
function intoClick() {
  router.push("/AddMaterial");
  dialogVisible.value = false;
}
function outClick() {
  router.push("/OutMaterial");
  dialogVisible.value = false;
}
const outLogin = () => {
  router.push({
    path: "Clock",
    state: { params: { type: "1", isScale: false } },
  });
};

</script>

<template>
  <div>
    <template v-if="false">
      <div class="materialWrap">
        <div
          class="materialItem"
          v-for="(item, index) in mList"
          :key="index"
          @click="itemClick(1)"
        >
          <img :src="item" />
          <div class="materialName">土豆</div>
        </div>
      </div>

      <h1>kkk</h1>
      <button @click="btnClick">test</button>
      <h2>{{ kg }}</h2>

      <button @click="addCLick">add</button>
    </template>
    <div class="dialog" v-if="dialogVisible">
      <div class="dialogBg"></div>
      <div class="content">
    

        <div class="outLog">

          <el-icon>
            <refresh-right
              style="width: 3em; height: 1em; margin-right: -14px"
            />
          </el-icon>
          <div style="font-weight: bold" @click="outLogin">退出账号</div>
        </div>

        <div class="flex">
          <div class="into contentBtn" @click="intoClick">
            <img src="../assets/u6.png" />
            <div class="dialogText">入仓</div>
          </div>
          <div class="out contentBtn" @click="outClick">
            <img src="../assets/u10.png" />
            <div class="dialogText">出仓</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/deep/ .el-icon {
  width: 2em;
}
.outLog {
  font-size: 20px;
  text-align: right;

  /* margin-bottom: 5px; */
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dialogText {
  margin-top: 30px;
}
.read-the-docs {
  color: #888;
}
.materialWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.materialItem {
  width: 33vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.materialItem img {
  width: 25vw;
  height: 25vw;
  display: block;
}
.materialItem .materialName {
  text-align: center;
  padding: 12px 0 30px;
  font-size: 40px;
  font-weight: bold;
}

.dialog {
  position: fixed;
  left: 0;
  top: 0;
  width:calc(100% - 40px);
  padding:   20px;
  height: calc(100% - 40px);
  background: #fff;
}
.dialogBg {
  width: 100%;
  height: 100%;
  /* background: #00000091; */
}

.dialog .content {
  /* width: 85vw;
  height: 40vw; */
  width:calc(100% - 40px);
  height: calc(100% - 40px);

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
}
.flex {
  display: flex;
  flex-direction: row;
  height: calc(100% - 40px);
}
.content .contentBtn {
  width: 50%;
  height: 100%;
  background: #64b9ff;
  background: rgba(153, 204, 255, 1);
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 90px;
}
.content .out {
  background: #55a77e;
  background: rgba(153, 255, 204, 1);
}
</style>
