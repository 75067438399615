<template>
  <div
    id="XKeyBoard"
    style=""
    :style="{
      width: width + 'vw',
      height: height + 'vw',
      left: left + 'px',
      top: top + 'px',
    }"
    ref="XKeyBoard"
  >
    <div class="x-top">
      <div @click.stop="clean">清空</div>
      <div></div>
      <div>
        <el-icon :size="20" @click="close()"><Close /></el-icon>
      </div>
      <div class="x-num" v-for="(ke, index) in keys" :key="index">
        <div @click.stop="entry(lk)" v-for="lk in ke" :key="'k' + lk">
          {{ lk }}
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  computed,
  watch,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "XKeyBoard",
  props: ["width", "height"],

  setup() {
    const { proxy: ctx } = getCurrentInstance(); // 可以把ctx当成vue2中的this
    const router = useRouter();
    const keybo = ref(null);
    const login = ref(null);
    const state = reactive({
      left: 0,
      top: 0,
      target: null,
      keys: [
        ["1", "2", "3"],
        ["4", "5", "6"],
        ["7", "8", "9"],
        [".", "0", "x"],
      ],
    });

    // 外部输入框事件传值过来
    const focuss = (left, top, input_height, height, width, target) => {
      // 展示键盘
      document.getElementById("XKeyBoard").style.display = "block";
      state.target = target;
      //   获取键盘的高度
      let key_height = document.getElementById("XKeyBoard").clientHeight;
      let key_width = document.getElementById("XKeyBoard").clientWidth;
      //   判定键盘需要处于input上面还是下面
      if (top < height / 2) {
        // input位置小于屏幕50%高度
        state.top = top + input_height * 2; // 键盘位置=input顶部坐标值+input高度*2
      } else {
        // 其他
        state.top = top - input_height - key_height; // 键盘顶部坐标值=input框顶部坐标值-input高度-键盘高度
      }
      // 右侧超出窗口，自动调整
      if (width - left < key_width) {
        state.left = width - input_height - key_width; // 键盘左边坐标值=屏幕宽-input高度-键盘宽度
      } else {
        state.left = left + input_height; // 键盘左边坐标值=input左边坐标值-input高度
      }
    };
    // 点击键盘事件
    const entry = (value) => {
      // 匹配0-9和.对input对象内容累加
      if (/^([0-9]{1})$/g.test(value) || value == ".") {
        state.target.value += value;
      } else {
        // 删除键清空input对象内容
        state.target.value = state.target.value.slice(
          0,
          state.target.value.length - 1
        );
      }
    };
    // 隐藏键盘自身
    const close = () => {
      document.getElementById("XKeyBoard").style.display = "none";
    };
    // 清空内容
    const clean = () => {
      state.target.value = "";
    };
    return {
      ...toRefs(state),

    };
  },
});
</script>
  
  <style lang="scss" scoped>
// 外框位置对照浏览器窗口大小
#XKeyBoard {
  position: fixed;
  border: 0.1vw solid #dcdee2;
  user-select: none;
  color: #515a6e;
  border-radius: 2px;
  background: white;
  z-index: 100;
  // 顶部居中
  .x-top {
    width: 100%;
    height: 11%;
    display: flex;
    justify-content: center;
    // 所有div横向居中
    div {
      display: flex;
      align-items: center;
      img {
        width: 2vw;
        height: 2vw;
      }
    }
    // 标题区向左对齐
    div:nth-child(1) {
      justify-content: flex-start;
      width: 6vw;
    }
    // 空白区占据宽度
    div:nth-child(2) {
      width: 12vw;
    }
    // 关闭按钮区右对齐
    div:nth-child(3) {
      width: 2vw;
      justify-content: flex-end;
      cursor: pointer;
    }
    // 所有按钮触发效果
    div:active {
      opacity: 0.6;
      background: rgb(90, 89, 89);
    }
  }
  //  数字区宽高、盒子、框线
  .x-num {
    width: 100%;
    height: 22.1%;
    display: flex;
    border-top: 1px solid #dcdee2;
    // 所有按钮居中，改变鼠标样式
    div {
      width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    // 按钮触发效果
    div:active {
      background: rgb(90, 89, 89);
      color: white;
    }
  }
  .x-num > div:nth-child(3n + 1),
  .x-num > div:nth-child(3n + 2) {
    border-right: 1px solid #dcdee2;
  }
}
</style>
  