//加法
export function accAdd(arg1 = 0, arg2 = 0) {
    var r1, r2, m, c;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
            arg1 = Number(arg1.toString().replace(".", "")) * cm;
            arg2 = Number(arg2.toString().replace(".", ""));
        }
    } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m;
}
//减法

export function accSub(arg1 = 0, arg2 = 0) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    const num=((arg1 * m - arg2 * m) / m).toFixed(n)
    return Number(num);
}
//乘法
export function accMul(arg1 = 0, arg2 = 0) {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    }
    catch (e) {
    }
    try {
        m += s2.split(".")[1].length;
    }
    catch (e) {
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

// 除法
export function accDiv1(arg1, arg2) {

    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    }
    catch (e) {
    }
    try {
        m += s2.split(".")[1].length;
    }
    catch (e) {
    }
    // let c = Number(num1.toString().replace(".", "")) / Number(num2.toString().replace(".", ""));
    return Number(s1.replace(".", "")) / Number(s2.replace(".", "")) * Math.pow(10, m);
    // let c = Number(s1.replace(".", "")) / Number(s2.replace(".", ""));
    
    // return accMul(c , Math.pow(10, m));
}
export function accDiv(arg1, arg2) {
    let l1 = 0, l2 = 0;
    try {
      l1 = arg1.toString().split(".")[1].length;
    } catch (e) {}
    try {
      l2 = arg2.toString().split(".")[1].length;
    } catch (e) {}
    let c = Number(arg1.toString().replace(".", "")) / Number(arg2.toString().replace(".", ""));
    return accMul(c , Math.pow(10, l2-l1));
}