<template>
  <div>
    <div class="header">
      <div class="flexItems justifyBetween titleBox cont">
        <h1 class="title">今日订单:</h1>
        <div class="flexItems">
          <div class="flexItems" @click="reload">
            <el-icon size="25">
              <refresh />
            </el-icon>
            <span class="butText">刷新任务</span>
          </div>
          <div class="butText" style="margin-left: 20px" @click="outLogin">
            退出当前账号
          </div>
        </div>
      </div>
    </div>
    <!-- 定位空间 -->
    <div class="headerH"></div>
    <div class="cont" v-if="purchaseOrderList.length">
      <el-card
        :shadow="v.shadow || ''"
        class="tradeList"
        v-for="(v, i) in purchaseOrderList"
        @click="cardClick(v)"
        :key="i"
      >
        <div class="flexItems">
          <img
            class="listImg"
            :src="
              v.goodsImg ||
              'https://img0.baidu.com/it/u=2404799835,4177816153&fm=253&fmt=auto&app=138&f=JPEG?w=515&h=500'
            "
            alt=""
          />
          <div class="tradeCont trader">
            <span class="tradeName">{{ v.goodsName }}</span>
            <span class="tradeName">{{ v.dishesWeights }}/kg</span>

            <span class="tradeName" style="margin-left: 30px">实际重量</span>
            <span class="tradeName">{{ v.processWeight || 0 }}/kg</span>
            <!-- <span class="tradeName">{{ v.practicalWeight || 0 }}/kg</span> -->
            <div style="margin-top: 20px" class="tradeName">
              <span style="font-weight: 400">切配方式：</span
              >{{ v.goodsTypeName }}
            </div>
          </div>
          <div class="flexItems">
            <div>照片：</div>
            <TakePhotoImg
              ref="PhotoImg"
              :row="v"
              @photoImgClick="photoClick($event, v)"
              @photoColse="photoColse(v)"
            ></TakePhotoImg>
            <el-button
              :disabled="v.isFig || v.butLoad"
              :type="v.isFig ? 'info' : 'success'"
              @click="warehousing(v, i)"
              size="large"
              style="  margin-left: 20px;
                          font-size: 30px;
                          padding: 30px;"
              >{{ v.isFig ? "已出餐" : "出净菜" }}</el-button
            >
          </div>
        </div>
      </el-card>
    </div>
    <el-empty description="暂无数据" v-else :image-size="200"></el-empty>
  </div>
</template>
<script>
import {
  getCurrentInstance,
  reactive,
  ref,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  toRefs,
} from "vue";
import { getNowFormatDate, getTomorrowDate } from "../../utils/time";
import { setToken } from "../../utils/auth";

import { parseTime } from "../../utils/ruoyi.js";
import { useRouter } from "vue-router";
import TakePhotoImg from "../../components/TakePhotoImg.vue";
import {
  updateProcessOrderRecord,
  listProcessOrderRecord,
} from "../../api/processScale";
import { uploadStaffPic } from "../WarehouseManagement/dataURLtoFile";
import { dicts } from "../../utils/getDict";
import { GetNumByUnit } from "../../utils/uni.js";
import { initScale } from "../../api/outMaterial.js";
import { accMul, accSub } from "../../utils/count";
export default defineComponent({
  components: { TakePhotoImg },
  name: "Withdrawal",
  setup() {
    const { proxy } = getCurrentInstance(); // 可以把ctx当成vue2中的this
    const router = useRouter();
    // const PhotoImg = ref();

    const state = reactive({
      purchaseOrderList: [],
      activeName: 1,
      formData: {
        purchaseOrder: 1,
        procureW: "100",
        supplier: "四川夸克餐饮管理有限公司",
        weigth: 0.5,
        pName: "空心菜",
      },
      kg: 0,
      ms: 0,
      arr: [],
      refreshData: null,
      setTiem: null,
      dictObj: {},
      offsetState: "",
      range: "",
    });
    // （10分钟）进行自动刷新
    onMounted(async () => {
      // 10秒钟刷新界面
      //   state.refreshData = setInterval(() => {
      //     reload();
      //     const date = parseTime(new Date());
      //     console.log("===》", date);
      //   }, 1000 * 60 * 10);
      state.dictObj = await dicts(
        ["unit_type", "goods_type", "cutting_type"],
        state.dictObj
      );
      getInitScale();
      getList();
      getWeight();
    });
    onBeforeUnmount(() => {
      //   clearInterval(state.refreshData);
      //   state.refreshData = null;
      console.log("卸载之前");
      clearInterval(state.setTiem);
    });
    // 加工称初始化
    const getInitScale = () => {
      initScale().then((res) => {
        state.range = res.data.offset;
        state.offsetState = res.data.offsetState;
      });
    };
    // 刷新界面
    const reload = () => {
      //   router.push("/");
      const date = parseTime(new Date());

      console.log(2342, "测温", date);
      window.location.reload();
    };
    // 获取列表
    const getList = () => {
      const date = new Date();
      let beginTime = parseTime(getNowFormatDate(date), "{y}-{m}-{d}");
      let endTime = parseTime(
        getNowFormatDate(getTomorrowDate(date)),
        "{y}-{m}-{d}"
      );

      const params = {
        pageNum: 1,
        pageSize: 2000,
        params: {
          beginTime: beginTime + " 00:00:00",
          endTime: endTime + " 23:59:59",
        },
        status: 0,
      };
      console.log(params, "====>");
      listProcessOrderRecord(params)
        .then((res) => {
          if (res.code == 200) {
            const data = res.rows;
            data.forEach((item) => {
              if (item.scaleStatus == 1) {
                item.isFig = true;
                item.processWeight = GetNumByUnit( item.processWeight,"g", "kg");
              } else {
                item.processWeight = 0
                // item.processWeight = item.processWeight
                //   ? GetNumByUnit(item.processWeight, "g", "kg")
                //   : 0;
              }
              item.dishesWeights = GetNumByUnit(
                item.goodsGrossWeight,
                // item.goodsSuttleWeight,
                "g",
                "kg"
              );
              item.goodsTypeName = dictsFilter(
                "cutting_type",
                item.cuttingType
              );
            });
            state.purchaseOrderList = data;
          }
        })
        .catch((err) => {});
    };

    // 选择当前需要秤重数据
    const cardClick = (row) => {
      if (row.processWeight && row.addImage && row.scaleStatus == 1) return;
      state.purchaseOrderList.forEach((item) => {
        item.shadow = item.processOrderId == row.processOrderId ? "always" : "";
        if (item.processOrderId == row.processOrderId) {
          item.processWeight = state.formData.weigth || 0;
        }
      });
    };
    // 字典值过滤当前值
    const dictsFilter = (dictsType, val) => {
      if (JSON.stringify(state.dictObj) == "{}") return;
      const dict = state.dictObj;

      const valObj = dict[dictsType].filter((item) => item.dictValue == val)[0];
      return (valObj && valObj.dictLabel) || "";
    };
    // 重量改变事件
    const weightSelection = (res) => {
      const row =
        state.purchaseOrderList.filter((item) => item.shadow == "always")[0] ||
        {};
      if (row.processWeight && row.addImage && row.scaleStatus == 1) return;

      if (JSON.stringify(row) !== "{}") {
        state.purchaseOrderList.forEach((item) => {
          if (row.processOrderId == item.processOrderId) {
            item.processWeight = state.formData.weigth;
          }
        });
      }
    };
    // 拍照确认照片
    const photoClick = (data, row) => {
      console.log(data,'2342342');
      row.loading = true;
      row.addImage = data.url;
      row.loading = false;
    };
    // 清空拍照图片
    const photoColse = (row) => {
      if (row.processWeight && row.addImage && row.scaleStatus == 1) return;
      // if (row.status == 0)
      //   return proxy.$message.warning("已出餐的图片不能删除图片");

      state.purchaseOrderList.forEach((item) => {
        if (item.processOrderId == row.processOrderId) {
          item.addImage = "";
          row.personnelUrl = "";
        }
      });
    };

    // 出净菜
    const warehousing = (row, i) => {
      row.butLoad = true;
      // 拍照
      if (!row.addImage) {
        automaticPhoto(row, i);
        return;
      }
      if (row.processWeight && row.addImage) {
        if (state.offsetState == 1) {
          getRange(row);
        } else {
          confirm(row);
        }
      } else {
        row.butLoad = false;
        return proxy.$message.warning("请检查重量及图片上是否完善！！！");
      }
    };
    // 自动调用拍照
    const automaticPhoto = async (row, i) => {
      if (proxy.$refs && !proxy.$refs.PhotoImg[i]) return (row.butLoad = false);
      const data = await proxy.$refs.PhotoImg[i].photograph(1) || {};
      setTimeout(() => {
        row.addImage = data.url || "";
        if (!row.addImage) return;
        warehousing(row, i);
      }, 200);
    };
    // 启用绝对值进行比较
    const getRange = (row) => {
      const practicalWeight = GetNumByUnit(row.processWeight, "kg", "g");
      // 取 偏离值的绝对值
      const num = accSub(row.goodsGrossWeight, practicalWeight);
      // const num = accSub(row.goodsSuttleWeight, practicalWeight);
      const numbe = state.range * -1;
      if (num >= numbe && num <= state.range) {
        confirm(row);
      } else {
        row.butLoad = false;
        let message =
          num < state.range ? "多了！！！" : num > numbe ? "少了！！！" : "";
        proxy.$message.warning(message);
      }
    };
    // 确认出净菜接口
    const confirm = (row) => {
      proxy.$messageBox
        .confirm(`是否确认出净菜?`, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
          cancelButtonClass: "cancel-button-class",
      customClass: "custom-class-text",
      confirmButtonClass: "confirm-button-class el-button--success",
        })
        .then(() => {
          let arrPor = [
            "processOrderId",
            "processWeight",
            "processPersonnelurl",
            "invoicesCode",
            "addImage",
            "unit",
          ];
          const data = objkeyFun(row, arrPor);
          data.processUserId = localStorage.getItem("userId");
          data.scaleStatus = "1";
          // data.practicalUnit = "0";
          data.practicalUnit = row.unit;
          // data.unit = "0";
          // data.practicalWeight = GetNumByUnit(data.practicalWeight, "kg", "g");
          data.processWeight = GetNumByUnit(data.processWeight, "kg", "g");
          const obj = Object.assign({}, row, data);
          console.log(obj, "=42342");

          updateProcessOrderRecord(obj)
            .then((res) => {
              if (res.code == 200) {
                // item.isFig
                getList();
              } else {
                row.scaleStatus = "0";
              }
              row.butLoad = false;

              proxy.$message.success(res.msg);
            })
            .catch((err) => {
              row.scaleStatus = "0";
              row.butLoad = false;
            });
        })
        .catch(() => {
          row.scaleStatus = "0";
          row.butLoad = false;
          // row.isFig=false
          proxy.$message("取消");
        });
    };

    // 接受秤重返回值
    // 获取数字
    const getFloat = function (v) {
      if (typeof v == "string") {
        const str = v.split(/[\t\r\f\n\s]*/g).join("");
        var regEx = /[^\d|^\.|^\-]/g;
        const num = str.replace(regEx, "");
        return parseFloat(num);
      }
    };
    // 每200 刷新秤端值
    const getWeight = () => {
      state.setTiem = setInterval(() => {
        state.ms += 200;
        if (state.ms == 300000) {
          state.ms = 0;
          getList();
        }
        const weight = getFloat(script.getWindowName());
        if (state.formData.weigth !== weight) {
          state.formData.weigth = weight;
          weightSelection(weight);
        }
      }, 200);
      return state.setTiem;
    };
    // 传参字段组装
    const objkeyFun = (row, arr) => {
      let data = {};

      Object.keys(row).forEach((key) => {
        arr.forEach((item) => {
          console.log(key === item, key, item);
          if (key === item) {
            data[key] = row[item] || "";
          }
        });
      });
      return data;
    };
    // 退出登录
    function outLogin() {
      setToken("");
      router.push("/login");
    }

    return {
      ...toRefs(state),
      reload,
      cardClick,
      weightSelection,
      photoClick,
      photoColse,
      warehousing,
      getWeight,
      outLogin,
      dictsFilter,
      automaticPhoto,
    };
  },
});
</script>
<style scoped>
>>> .el-card.is-always-shadow {
  box-shadow: 0px -5px 25px rgba(0.5, 0, 0, 0.5);
}
.header {
  position: fixed;
  background: #fff;
  z-index: 1;
  width: 100%;
}
.justifyBetween {
  justify-content: space-between;
}
.butText {
  font-size: 23px;
}
.headerH {
  height: 70px;
}
.tabList {
  border: 1px solid #e4e7ed;
}
.tabItem {
  border-right: 1px solid #e4e7ed;
  padding: 15px;
  font-size: 25px;
  font-weight: bold;
  color: #424141;
  border-radius: none;
}
.tabSelect {
  background: #95d475;
  border-right-color: #95d475;
  color: #fff;
}
>>> .el-input__wrapper.is-focus {
  box-shadow: 0px 0px 0 1px #95d475 inset;
}
>>> .el-input-number__decrease:hover,
>>> .el-input-number__increase:hover {
  color: #95d475;
}
.cont {
  position: relative;
}
/* 屏幕小于1280px */
@media screen and (max-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/*  屏幕小于1520或大于1280 */
@media screen and (max-width: 1520px) and (min-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/* 屏幕大于1520px */
@media screen and (min-width: 1520px) {
  .cont {
    width: 75%;
    margin: 0 auto;
  }
}
.tradeList {
  margin: 6px 0 10px;
  border-radius: 20px;
}
.trader {
  flex: 1;
  /* text-align: center; */
}
.tabBox {
  padding: 0 30px;
  display: flex;
  /* width: 60%; */
}
.tradeName {
  font-size: 23px;
  margin: 0 5px;
  font-weight: bold;
}
.tradePrice {
  margin: 0 5px;
}
.tradeCont {
  margin: 10px 15px;
}
.listImg {
  width: 220px;
  height: 150px;
}
.el-tabs--border-card {
  width: 100%;
  margin-right: 15px;
}

.wrap {
  /* position: fixed; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.titleBox {
  margin-left: 2px;
  margin: auto;
}
.title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
  /* text-indent: 20px; */
}
</style>