<!-- 回餐记录 -->
<template>
  <div>
    <div class="header">
      <div class="flexItems justifyBetween titleBox cont">
        <div class="flex title-box">
          <h1
            class="title"
            :class="selectMenc == 1 ? 'fontSize' : ''"
            @click="menuClick(1)"
          >
            今日菜单
          </h1>
          <h1
            class="title"
            :class="selectMenc == 2 ? 'fontSize' : ''"
            @click="menuClick(2)"
          >
            昨日菜单
          </h1>
          <!-- <el-button type="success" round size="default">今日菜单</el-button> -->
        </div>

        <div class="flexItems">
          <div class="flexItems" @click="reload">
            <el-icon size="25">
              <refresh />
            </el-icon>
            <span class="butText">刷新任务</span>
          </div>
          <div class="butText" style="margin-left: 20px" @click="closeBtnCLick">
            返回
          </div>
        </div>
      </div>
    </div>
    <!-- 占位符（请勿删除） -->
    <div style="height: 70px"></div>
    <!-- 占位符 end -->
    <div class="cont" v-if="purchaseOrderList.length">
      <el-card
        :shadow="v.shadow || ''"
        class="tradeList"
        v-for="(v, i) in purchaseOrderList"
        @click="cardClick(v)"
        :key="i"
      >
        <div class="flexItems">
          <img
            class="listImg"
            :src="
              v.canteenDishesImg ||
              'https://img0.baidu.com/it/u=2404799835,4177816153&fm=253&fmt=auto&app=138&f=JPEG?w=515&h=500'
            "
            alt=""
          />
          <div class="tradeCont trader">
            <div>
              <span class="tradeName">{{ v.canteenDishesName }}</span>
              <span class="tradeName">{{ v.reduceNoW || 0 }}/kg</span>
            </div>
            <div style="margin-top: 15px">
              <span class="tradeName">退餐重量</span>
              <span class="tradeName">{{ v.addweight || 0 }}/kg</span>
            </div>
          </div>
          <div class="flexItems">
            <div>照片：</div>
            <TakePhotoImg
              ref="PhotoImg"
              :row="v"
              @photoImgClick="photoClick($event, v)"
              @photoColse="photoColse(v)"
            ></TakePhotoImg>
            <el-button
              :disabled="
                v.isRecycle == '1' ||
                (v.addweight && v.addImage && v.scaleStatus == 1) ||
                v.butLoad
              "
              :type="
                v.isRecycle == '1' ||
                (v.addweight && v.addImage && v.scaleStatus == 1)
                  ? 'info'
                  : 'success'
              "
              @click="warehousing(v, i)"
              size="large"
              style="margin-left: 20px; font-size: 25px"
              >{{
                v.isRecycle == "1" ||
                (v.addweight && v.addImage && v.scaleStatus == 1)
                  ? "已退餐"
                  : "退餐"
              }}</el-button
            >
          </div>
        </div>
      </el-card>
    </div>
    <el-empty description="暂无数据" v-else :image-size="200"></el-empty>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  reactive,
  toRefs,
  ref,
  computed,
  onMounted,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import TakePhotoImg from "../../components/TakePhotoImg.vue";
import {
  getNowFormatDate,
  getTomorrowDate,
  getYesterdayDate,
} from "../../utils/time";
import { uploadStaffPic } from "../WarehouseManagement/dataURLtoFile";
import {
  listScaleReturnRecord,
  editScaleReturnRecord,
} from "../../api/quitMeal";
import { parseTime } from "../../utils/ruoyi.js";
import { GetNumByUnit } from "../../utils/uni.js";
import { initScale } from "../../api/outMaterial.js";
import { accMul, accSub } from "../../utils/count";
export default defineComponent({
  components: { TakePhotoImg },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      purchaseOrderList: [],
      setTiem: null,
      formData: {
        weigth: 0.001,
      },
      offsetState: "",
      range: "",
      params: {
        pageNum: 1,
        pageSize: 2000,
        // status: 0,
      },
      selectMenc: 1,
    });
    onMounted(() => {
      // 10秒钟刷新界面
      //   state.refreshData = setInterval(() => {
      //     reload();
      //     const date = parseTime(new Date());
      //     console.log("===》", date);
      //   }, 1000 * 60 * 10);
      // getInitScale();
      menuClick(1);
      getWeight();
    });
    onBeforeUnmount(() => {
      console.log("卸载之前");
      clearInterval(state.setTiem);
    });
    // 加工称初始化
    const getInitScale = () => {
      initScale().then((res) => {
        state.range = res.data.offset;
        state.offsetState = res.data.offsetState;
      });
    };
    // 刷新界面
    const reload = () => {
      //   router.push("/");
      const date = parseTime(new Date());

      console.log(2342, "测温", date);
      window.location.reload();
    };
    // 今日菜单、昨日菜单
    const menuClick = (type) => {
      state.selectMenc = type;
      const date = new Date();
      // 昨日
      const yesterday = parseTime(
        getNowFormatDate(getYesterdayDate(date)),
        "{y}-{m}-{d}"
      );
      // 今日
      const day = parseTime(date, "{y}-{m}-{d}");
      const beginTime = type == 1 ? day : yesterday;
      const endTime = type == 1 ? day : yesterday;
      state.params.params = {
        beginTime: beginTime + " 00:00:00",
        endTime: endTime + " 23:59:59",
      };
      getList();
    };
    // 获取列表
    const getList = () => {
      const date = new Date();
      let beginTime = parseTime(getNowFormatDate(date), "{y}-{m}-{d}");
      let endTime = parseTime(
        getNowFormatDate(getTomorrowDate(date)),
        "{y}-{m}-{d}"
      );

      const params = {
        pageNum: 1,
        pageSize: 2000,
        params: {
          beginTime: beginTime + " 00:00:00",
          endTime: endTime + " 23:59:59",
        },
        status: 0,
      };
      state.params.status=0
      console.log(state.params);
      state.params.state=0
      listScaleReturnRecord(state.params)
        .then((res) => {
          if (res.code == 200) {
            const data = res.rows;
            if (data.length) {
              data.forEach((item) => {
                item.addImage = item.addimage;
                item.reduceNoW = GetNumByUnit(item.reduceNo, "g", "kg");
                if (item.addweight && item.addImage && item.scaleStatus == 1) {
                  item.addweight = GetNumByUnit(item.addweight, "g", "kg");
                  item.isFig = true;
                } else {
                  item.addweight = item.addweight
                    ? GetNumByUnit(item.addweight, "g", "kg")
                    : 0;
                }
              });
            }
            state.purchaseOrderList = data;
          }
        })
        .catch((err) => {});
    };
    // 返回
    function closeBtnCLick() {
      router.push("/returnMeal");
    }
    // 选择当前需要秤重数据
    const cardClick = (row) => {
      if (row.addweight && row.addImage && row.scaleStatus == 1) return;
      state.purchaseOrderList.forEach((item) => {
        item.shadow =
          item.canteenReturnId == row.canteenReturnId ? "always" : "";

        if (item.canteenReturnId == row.canteenReturnId) {
          item.addweight = state.formData.weigth || 0;
        }
      });
    };
    // 拍照确认照片
    const photoClick = (data = {}, row, i) => {
      row.loading = true;
      row.addImage = data&&data.url||"";
      row.addimage = data&&data.url||"";
      row.loading = false;
    };
    // 清空拍照图片
    const photoColse = (row) => {
      if (row.addweight && row.addImage && row.scaleStatus == 1) return;

      state.purchaseOrderList.forEach((item) => {
        if (item.canteenReturnId == row.canteenReturnId) {
          item.addImage = "";
          row.personnelurl = "";
        }
      });
    };
    // 出餐
    const warehousing = (row, i) => {
      row.butLoad = true;
      // 拍照
      if (!row.addImage) {
        automaticPhoto(row, i);
        return;
      }
      const practicalWeight = GetNumByUnit(row.addweight, "kg", "g");

      if (row.addweight && row.addImage) {
        if (row.reduceNo < practicalWeight)
          return proxy.$message.warning("多了！！！");
        confirm(row);
      } else {
        proxy.$message.warning("请检查重量(重量不能为0)及图片是否完善");
        row.butLoad = false;
      }
    };
    // 自动调用拍照
    const automaticPhoto =async (row, i) => {
      if (!proxy.$refs.PhotoImg || !proxy.$refs.PhotoImg[i])
        return (row.butLoad = false);
        const data = await proxy.$refs.PhotoImg[i].photograph(1) || {};
      // row.isFig=true
      setTimeout(() => {
        row.addImage = data.url || "";
        row.addimage = data.url || "";
        if (!row.addImage) return;
        warehousing(row, i);
      }, 200);
    };
    // 出餐接口
    const confirm = (row) => {
      proxy.$messageBox
        .confirm(`是否确认退餐?`, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
          confirmButtonClass: "el-button el-button--success el-button--large",
        })
        .then(() => {
          row.isRecycle = "1";
          const obj = {
            returnUserId: localStorage.getItem("userId"),
            scaleStatus: "1",
            // practicalUnit: "0",
            practicalUnit: row.unit,
            // unit: "0",
            addweight: GetNumByUnit(row.addweight, "kg", "g"),
          };
          // PhotoImg.value[i].photograph();
          const data = Object.assign({}, row, obj);
          editScaleReturnRecord(data).then((res) => {
            if (res.code == 200) {
              proxy.$message.success(res.msg);
              getList();
            } else {
              row.scaleStatus = "0";
              proxy.$message.success(res.msg);
            }
            row.butLoad = false;
          }).catch(err=>{
            row.scaleStatus = "0";
            row.butLoad = false;

          });
        })
        .catch(() => {
          row.scaleStatus = "0";
          proxy.$message("取消");
          row.butLoad = false;
        });
    };
    // 重量改变事件
    const weightSelection = (res) => {
      const row =
        state.purchaseOrderList.filter((item) => item.shadow == "always")[0] ||
        {};
      console.log(state.formData.weigth, row, "weightSelection");
      if (row.addweight && row.addimage && row.scaleStatus == 1) return;
      row.addweight = state.formData.weigth;
      if (JSON.stringify(row) !== "{}") {
        state.purchaseOrderList.forEach((item) => {
          if (row.canteenReturnId == item.canteenReturnId) {
            item.addweight = state.formData.weigth;
          }
        });
        console.log(row, state.formData);
      }
    };
    // 接受秤重返回值
    // 获取数字
    const getFloat = function (v) {
      if (typeof v == "string") {
        const str = v.split(/[\t\r\f\n\s]*/g).join("");
        var regEx = /[^\d|^\.|^\-]/g;
        const num = str.replace(regEx, "");
        return parseFloat(num);
      }
    };
    // 每200 刷新秤端值
    const getWeight = () => {
      state.setTiem = setInterval(() => {
        const weight = getFloat(script.getWindowName());
        if (state.formData.weigth !== weight) {
          state.formData.weigth = weight;
          weightSelection(weight);
        }
      }, 200);
      return state.setTiem;
    };
    return {
      ...toRefs(state),
      reload,
      closeBtnCLick,
      cardClick,
      photoClick,
      photoColse,
      warehousing,
      getWeight,
      getFloat,
      menuClick,
    };
  },
});
</script>

<style  scoped>
>>> .el-card.is-always-shadow {
  box-shadow: 0px -5px 25px rgba(0.5, 0, 0, 0.5);
}
.trader {
  flex: 1;
  /* text-align: center; */
}
.tradeName {
  font-size: 23px;
  margin: 0 5px;
  font-weight: bold;
}
.tradeCont {
  margin: 10px 15px;
}
.listImg {
  width: 220px;
  height: 150px;
}
.tradeList {
  margin: 6px 0 10px;
  border-radius: 20px;
}
.header {
  position: fixed;
  background: #fff;
  z-index: 1;
  width: 100%;
  padding-top: 10px;
}
.butText {
  font-size: 23px;
  cursor: pointer;
}
.titleBox {
  margin-left: 2px;
  margin: auto;
}
.title {
  text-align: left;
  font-size: 30px;
  padding: 14px 20px;
  cursor: pointer;
}
.title-box {
  background: rgb(238, 238, 239);
  border-radius: 21px;
  /* margin-top: 5px; */
  color: rgb(96, 98, 102);
}
.fontSize {
  color: #ffff;
  background: #67c23a;
  /* padding: 10px; */
  /* line-height: 48px; */
  border-radius: 21px;
  font-weight: bold;
}
.cont {
  position: relative;
}
/* 屏幕小于1280px */
@media screen and (max-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/*  屏幕小于1520或大于1280 */
@media screen and (max-width: 1520px) and (min-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/* 屏幕大于1520px */
@media screen and (min-width: 1520px) {
  .cont {
    width: 75%;
    margin: 0 auto;
  }
}
</style>