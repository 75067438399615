



<template>
  <div
    class="cameraWrap"
    v-loading="row.loading"
    element-loading-text="图片上传中..."
  >
    <div class="cameraCont">
        <!-- height="150" -->
      <video
        ref="video"
        width="220"
        autoplay height="150"
        class="cameraVideo"
        v-show="row.shadow && !row.addImage"
        @click="photograph"
      ></video>
      <img style="width: 100%" :src="row.addImage" v-if="row.addImage" />
      <canvas ref="canvas" width="220" height="150" v-show="false"></canvas>
      <el-icon class="colse" v-show="row.addImage" @click="test"
        ><CircleCloseFilled
      /></el-icon>
      <div
        class="camerText"
        @click="photograph"
        v-show="!row.shadow && !row.addImage"
      >
        <el-icon color="#8c939d" style="font-size: xxx-large"><Plus /></el-icon>
        <div style="color: #8c939d">点击拍照</div>
      </div>
    </div>

    <!-- 隐藏摄像头 -->
    <video
      ref="video1"
      width="220"
      height="150"
      autoplay
      class="cameraVideo"
      v-show="false"
    ></video>
    <canvas ref="canvas1" width="220" height="150" v-show="false"></canvas>
    <!-- <div class="take" @click="photograph">点击拍照</div> -->
  </div>
</template>
<script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  computed,
  onMounted,
  defineExpose,
  watch,
} from "vue";
import { initCamera } from "../utils/TakePhotoUtil";
import { ElMessage, ElMessageBox } from "element-plus";
import { uploadStaffPic } from "../utils/dataURLtoFile";

export default defineComponent({
  props: {
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
    addImgUrl:{
      type: String,
      default: ''
    },
  },
  
  setup() {
    const { proxy } = getCurrentInstance(); // 可以把ctx当成vue2中的this
    const state = reactive({
      videoVisible: true,
      videoVisible1: true,
      imgUrl:''
    });


    onMounted(() => {
      getDevices().then((res) => {
        if (res.length != 0) {
          camera(res);
        }
      });
    });
    /** @desc 获取计算机外设列表 储存摄像头数据 */
    const getDevices = () => {
      return new Promise((resolve, reject) => {
        if (
          !navigator.mediaDevices ||
          !navigator.mediaDevices.enumerateDevices
        ) {
          window.alert("不支持 mediaDevices.enumerateDevices()");
        }
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            console.log(devices);
            let cameraList = [];
            devices.forEach((device, index) => {
              if (
                device.kind &&
                device.kind === "videoinput" &&
                device.deviceId
              ) {
                cameraList.push({
                  deviceId: device.deviceId,
                });
              } else {
                console.log("未连接到摄像头");
              }
            });
            resolve(cameraList);
          })
          .catch((err) => {
            console.log(err.name + ": " + err.message);
            reject();
          });
      });
    };
    // 关闭摄像头
    const test = () => {
      proxy.$emit("photoColse");
      // state.videoVisible = true;
      state.videoVisible1 = true;
    };
    // 同步调用摄像头
    const camera = async (res) => {
      console.log(res[0], "======>");
      // const data=res.filter(item=>item.kind:'')
      const ress = await initCamera(res[0]);
      proxy.$refs["video"].srcObject = ress;
      proxy.$refs["video"].play();
      const videH = await initCamera(res[1]);
      proxy.$refs["video1"].srcObject = videH;
      proxy.$refs["video1"].play();
    };
    // 拍照地址
    const photoImgUrl = () => {
      let ctx = proxy.$refs["canvas"].getContext("2d");

      // 把当前视频帧内容渲染到canvas上
      ctx.drawImage(proxy.$refs["video"], 0, 0, 240, 180);

      // 转base64格式、图片格式转换、图片质量压缩
      var imgBase64 = proxy.$refs["canvas"].toDataURL("image/jpeg", 0.7);
      let ctx1 = proxy.$refs["canvas1"].getContext("2d");
      // // imgUrl = imgBase64;
      // // 把当前视频帧内容渲染到canvas上
      ctx1.drawImage(proxy.$refs["video1"], 0, 0, 240, 180);

      // // 转base64格式、图片格式转换、图片质量压缩
      var imgBase641 = proxy.$refs["canvas1"].toDataURL("image/jpeg", 0.7);

      const data = {
        imgUrl: imgBase64,
        personnelUrl: imgBase641,
      };
      state.videoVisible = false;
      state.videoVisible1 = false;
      return data;
    };
    // 获取拍照后的图片
    const photograph =async (type=0) => {
      const data = photoImgUrl();
      console.log(2323);
 let photoImgData= await photoImgUpload(data,type)

      proxy.$emit("photoClick", data);

      state.videoVisible = false;
      state.videoVisible1 = false;
      return photoImgData
    };
    // 提示
    const confirm = (str) => {
      console.log("已调用");
    };
    const photoImgUpload=async (data,type)=>{
      return  new Promise(async (resolve, reject) => {
        try{
        const imgData =await uploadStaffPic("file/upload", data.imgUrl, ".png");
        console.log(imgData,'12342342',type,'234234');
        if(type!=1){
          proxy.$emit("photoImgClick", imgData);
        }
        state.imgUrl=imgData.url||''
        resolve(imgData)
      } catch (err){
        if(type!=1){
          proxy.$emit("photoImgClick", null);
        }
        resolve(null)
      }
      })
   
      // return imgUrl
    }

    return {
      ...toRefs(state),
      getDevices,
      test,
      photograph,
      photoImgUpload,
      confirm,
    };
  },
});
</script>
<style scoped>
.cnfirBut {
}
.colse {
  position: absolute;
  top: -4px;
  right: -3px;
}
.cameraCont {
  border: 1px dashed #d8d8d8;
  position: relative;
  width: 220px;
  height: 150px;
  /* height: 100%; */
}
.camerText {
  text-align: center;
  /* line-height: 180px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.cameraWrap {
  display: flex;
  flex-direction: column;
  width: 222px;
}
.photoGraphi {
  text-align: center;
  font-size: 18px;
}
.cameraVideo {
  /* border: 1px solid #d8d8d8; */
}
.take {
  width: 100%;
  height: 60px;
  text-align: center;
  background: #3ba0ff;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
</style>
