var isInit = false
var initSuccess = false
var videoSrcObject = null
var callback = []
let arr = []




export function initCamera(deviceIds) {
    // callback = _callback
    // 打开多个摄像头
    let video = null
    console.log(deviceIds,'=video');
    if (deviceIds) {
        video = { video: { deviceId: deviceIds.deviceId } }
    }else{
        video = { video:true}
    }

    return new Promise((resolve, reject) => {
        isInit = true
        navigator.mediaDevices.getUserMedia(video).then((success) => {
            videoSrcObject = success
            resolve(success)
        }).catch(error => {
            console.log("打开摄像头失败")
            reject(error)
        })
    })
}


