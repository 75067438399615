export function getNowFormatDate(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}


// 明天
export function getTomorrowDate(str) {
  var date = new Date(str)
  var d = date.getDate()
  date.setDate(d + 1)
  return date.getTime()
}
// 昨日
export function getYesterdayDate(str,day=1) {
  var date = new Date(str)
  var d = date.getDate()
  date.setDate(d - day)
  return date.getTime()
}
// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

// 计算天数
export function getDaysBetween(date1, date2) {
  var startDate = Date.parse(date1);
  var endDate = Date.parse(date2);
  if (startDate > endDate) {
    return 0;
  }
  if (startDate == endDate) {
    return 1;
  }
  var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
  return days;
}


// 计算小时
export function setHoursTime(currentDate,hours = 24) {
  let t = new Date(currentDate).getTime() + hours * 60 * 60 * 1000;   //24小时 * 60分钟 * 60秒 * 1000
  let d = new Date(t);
  let theMonth = d.getMonth() + 1;
  let theDate = d.getDate();
  let theHours = d.getHours();
  let theMinutes = d.getMinutes();
  let s=d.getSeconds()
  if (theMonth < 10) {
    theMonth = '0' + theMonth
  }
  if (theDate < 10) {
    theDate = '0' + theDate
  }
  if (theHours < 10) {
    theHours = '0' + theHours
  }
  if (theMinutes < 10) {
    theMinutes = '0' + theMinutes
  }
  if (s < 10) {
    s = '0' + s
  }
  let date = d.getFullYear() + '-' + theMonth + '-' + theDate
  let time = theHours + ':' + theMinutes
  let Spare = date + ' ' + time
  console.log(date)
  console.log(time)
  console.log(Spare)
  return Spare
}