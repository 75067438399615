<!--
 * @Descripttion: 
 * @version: 
 * @Date: 2021-04-20 11:06:21
 * @LastEditors: huzhushan@126.com
 * @LastEditTime: 2022-09-27 18:24:27
 * @Author: huzhushan@126.com
 * @HomePage: https://huzhushan.gitee.io/vue3-element-admin
 * @Github: https://github.com/huzhushan/vue3-element-admin
 * @Donate: https://huzhushan.gitee.io/vue3-element-admin/donate/
-->
<template>
  <div class="login" v-if="!isShow">
    <el-form class="form" :model="model" :rules="rules" ref="loginForm">
      <div class="logoWrap">
        <img src="../assets/login/logo.png" />
        <div class="loginText">厨创数智餐厅</div>
      </div>
      <div class="loginWBox" v-show="isLoginW">
        <!-- <img
          src="https://files.axshare.com/gsc/X2432I/2c/d3/68/2cd368e97e964eb5a416fe2306e3b553/images/%E7%99%BB%E5%BD%95%E7%95%8C%E9%9D%A2/u1.svg?pageId=e07e555d-4399-424f-96c6-f1e73f438958"
        /> -->
        <div id="loginContainer"></div>

        <!-- <div class="loginW">请用微信扫码登录</div> -->
      </div>
      <div
        v-if="isWxMobile"
        style="margin-bottom: 15px; font-weight: bold; color: #e6a23c"
      >
        该微信号未绑定手机号，请绑定手机
      </div>
      <div v-show="!isLoginW || isWxMobile">
        <el-form-item prop="mobile">
          <el-input
            size="large"
            class="text"
            v-model="model.mobile"
            clearable
            @clear="input = ''"
            @focus="focusMobile($event, 'mobile')"
            @blur="blurMobile($event, 'mobile')"
          >
            <template #prepend> 手机号 </template>
            <template #append>
              <div class="text" @click="codeClick">{{ tips }}</div></template
            >
          </el-input>
        </el-form-item>
        <el-form-item prop="smsCode">
          <el-input
            class="text"
            size="large"
            @focus="focusMobile($event, 'smsCode')"
            @blur="blurMobile($event, 'smsCode')"
            v-model="model.smsCode"
            clearable
          >
            <template #prepend> 动态码 </template>
          </el-input>
        </el-form-item>
      </div>

      <el-button
        v-if="!isWxMobile"
        type="success"
        style="margin-bottom: 15px"
        :style="{ textIndent: isLoginW ? '40px' : '' }"
        link
        @click="switchClick"
        >{{ !isLoginW ? "切换为微信扫码登录" : "切换为手机登录" }}</el-button
      >
      <el-form-item v-if="!isLoginW || isWxMobile">
        <el-button
          :loading="loading"
          type="success"
          class="btn"
          size="large"
          @click="submit"
          >{{ isWxMobile ? "确认" : btnText }}</el-button
        >
      </el-form-item>
    </el-form>

    <!-- 键盘 -->
    <!-- <div class="el-drawer keyboard">
      <number-keyboard
        v-if="isKeyboard"
        @onChange="onChange"
        @onKeyPress="onkeyPress"
        :input="model[currentProp]"
      ></number-keyboard>
    </div> -->
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  computed,
  onMounted,
  watch,
  nextTick,
} from "vue";
import { setToken } from "../utils/auth";
import { useRouter, useRoute } from "vue-router";
import wx from "weixin-js-sdk";
import { post, get, postFun } from "../api/requestUtil";
// import numberKeyboard from "../components/numberKeyboard.vue";

export default defineComponent({
  // components: { numberKeyboard },
  name: "login",
  setup() {
    const { proxy } = getCurrentInstance(); // 可以把ctx当成vue2中的this
    const router = useRouter();

    const getRules = () => ({
      mobile: [
        {
          required: true,
          message: "请输入手机号",
          trigger: "blur",
        },
        {
          pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
          message: "请输入合法手机号",
          trigger: "blur",
        },
      ],
      smsCode: [
        {
          required: true,
          message: "请输入手机动态码",
          trigger: "blur",
        },
        {
          min: 6,
          max: 6,
          message: "请输入六位手机动态码",
          trigger: "blur",
        },
      ],
    });
    const state = reactive({
      input: "",
      model: {
        mobile: "",
        smsCode: "",
        // loginType: "warehouse_scale",
        loginType: "10",
      },
      isLoginW: false,
      timeleft: 60,
      tips: "获取验证码",
      isTips: false,
      rules: getRules(),
      loading: false,
      isShow: false,
      btnText: "登录",
      loginForm: ref(null),
      // 是否显示键盘
      isKeyboard: false,
      isWxMobile: false, //是否为微信手机号绑定
      currentProp: "mobile",
      submit: async () => {
        /**
         * 登录类型
         *  "warehouse_scale": 仓管称端
         *  "process_scale": 加工称端
         * "process_screen":加工屏幕
         * "canteen_scale": 餐厅称端
         */
        let url = state.isWxMobile
          ? "auth/wechat/oauth/bind_mobile"
          : "/auth/scale-login";
        // 判断登录人，需要进那个模块
        const res = await postFun(url, state.model);
        loginSubmit(res);
        return;

        const data = res.data;
        const roles = data.roles;
        localStorage.setItem("roles", roles);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("userName", data.userName);
        localStorage.setItem("mobile", state.model.mobile);

        setToken(data.access_token);
        // 获取当前登录人信息
        getUserInfo();

        if (roles.includes("warehouse_scale")) {
          router.push({ path: "/Clock", state: { params: { type: "0" } } });
        } else if (roles.includes("process_scale")) {
          router.push({ path: "/processScale" });
        }
        // else if (roles.includes("process_screen")) {
        //   router.push({ path: "/processScreen" });
        // }
        else if (roles.includes("canteen_scale")) {
          router.push({ path: "/Clock", state: { params: { type: "0" } } });
        } else {
          proxy.$message.warning("该手机号未注册");
        }
        console.log(res);
        // 仓管秤端 (需要锁屏)
        // router.push({ path: "Clock", state: { params: { type: "0" } } });
      },
    });
    const switchClick = () => {
      state.isLoginW = !state.isLoginW;
    };
    // url地址后的参数
    const urlObject = getParams(window.location);

    const onChange = (e) => {
      console.log(state.currentProp, "23423423");
      state.model[state.currentProp] = e;
      state.loginForm.validateField(state.currentProp)
    };
    const onkeyPress = (button) => {
      if (button === "{enter}" || button === "{close}") {
        state.isKeyboard = false;
        state.currentProp = "";
      }
    };
    //
    const focusMobile = (e, porp) => {
      // if (state.currentProp != porp) {
      //   state.isKeyboard = false;
      //   state.currentProp = "";
      // }
return
      nextTick(() => {
        state.isKeyboard = true;
        state.currentProp = porp;
        state.model[state.currentProp] = state.model[porp]
        // state.model[state.currentProp] = state.model[state.currentProp];
      });

      console.log(e, "23424聚焦点");
    };
    const blurMobile = (e, porp) => {
      // state.model.mobile = state.input;
      // state.isKeyboard = false;
      return
      if (state.currentProp != porp) {
        state.isKeyboard = false;
        state.currentProp = "";
      }
      state.currentProp = porp;
      state.model[state.currentProp] = state.model[state.currentProp]
      console.log(e, "失去焦点");
    };

    onMounted(() => {
      console.log(window.location, urlObject, "234234");
      if (urlObject.code) return;

      loadWX();
    });
    //获取url地址后的参数
    function getParams(url) {
      const urlObject = new URL(url);
      const params = {};
      for (const [key, value] of urlObject.searchParams.entries()) {
        params[key] = value;
      }
      return params;
    }
    // 发起后端请求获取微信配置
    const loadWX = () => {
      const params = {
        loginType: 10,
      };
      get(`/system/wechat/web_login_info`, params).then((res) => {
        if (res.code == 200) {
          const data = res.data;
          //发请求获取微信扫码二维码需要参数
          //咱们在想后台的服务器发请求,获取微信扫码登录页面参数
          //还需要携带一个参数:告诉后台服务器用户授权成功以后重定向项目某一个页面
          // let redirect_URL = encodeURIComponent(window.location.origin + "/wxlogin");

          //生成微信扫码登录二维码页面
          //@ts-ignore
          new WxLogin({
            self_redirect: false, //true:手机点击确认登录后可以在 iframe 内跳转到 redirect_uri
            id: "loginContainer", //显示二维码容器设置
            appid: data.appid, //应用位置标识appid
            scope: "snsapi_login", //当前微信扫码登录页面已经授权了
            redirect_uri: data.redirectUri, //填写授权回调域路径,就是用户授权成功以后，微信服务器向公司后台推送code地址
            state: data.state, //state就是后台服务器重定向的地址携带用户信息
            style: "black",
          });
        }
      });
    };
    // 获取扫码成功后的调用接口
    const scanQRCode = () => {
      const params = Object.assign({ loginType: "10" }, urlObject);

      post(`/auth/wechat/oauth/login`, params).then((res) => {
        if (res.code == 200) {
          const data = res.data;
          state.model.sign = data.sign;
          state.isWxMobile = data.sign ? true : false;
          state.isShow = false;

          if (data.access_token) {
            // window.location.href = window.location.origin;
            state.isShow = true;
            loginSubmit(res);
          } else {
            // state.submit();
          }
        }
      });
    };
    if (urlObject.code) {
      scanQRCode();
    }
    // 登录接口
    const loginSubmit = async (res = {}) => {
      /**
       * 登录类型
       *  "warehouse_scale": 仓管称端
       *  "process_scale": 加工称端
       * "process_screen":加工屏幕
       * "canteen_scale": 餐厅称端
       */

      // 判断登录人，需要进那个模块
      // const res = await postFun(url, state.model);
      const data = res.data;
      const roles = data.roles;
      const mobile = state.model.mobile ? state.model.mobile : data.mobile;

      localStorage.setItem("roles", roles);
      localStorage.setItem("userId", data.userId);
      localStorage.setItem("userName", data.userName);
      localStorage.setItem("mobile", mobile);
      console.log(mobile, "mobile");

      setToken(data.access_token);
      // 获取当前登录人信息
     await getUserInfo();

      let routerUrl =
        roles.includes("warehouse_scale") || roles.includes("canteen_scale")
          ? "#/Clock?type=0"
          : roles.includes("process_scale")
          ? "#/processScale"
          : "";
      if (state.isShow || state.isWxMobile) {
        window.location.replace(window.location.origin + routerUrl);
      }
      if (roles.includes("warehouse_scale")) {
        // window.location.href=window.location.origin+'/#/Clock?type=0'
        router.push({ path: "/Clock", state: { params: { type: "0" } } });
      } else if (roles.includes("process_scale")) {
        // window.location.href=window.location.origin+'/#/processScale'

        router.push({ path: "/processScale" });
      }
      // else if (roles.includes("process_screen")) {
      //   router.push({ path: "/processScreen" });
      // }
      else if (roles.includes("canteen_scale")) {
        // window.location.href=window.location.origin+'/#/Clock'
        router.push({ path: "/Clock", state: { params: { type: "0" } } });
      } else {
        proxy.$message.warning("该手机号未注册");
      }
    };
    // 发起验证码请求接口
    const codeClick = () => {
      if (state.isTips) return proxy.$message.warning("倒计时结束后再发送");
      state.loginForm.validateField("mobile", async (res) => {
        if (res) {
          state.isTips = true;
          getCode();
          const code = await get("system/message/send-mobile-code", {
            mobile: state.model.mobile,
          });
          state.timeleft = 60;
        }
      });
    };

    // 获取验证码
    const getCode = () => {
      var downloadTimer = setInterval(function () {
        state.timeleft--; // 时间减一秒
        state.tips = state.timeleft + "获取"; // 显示时间到页面上
        if (state.timeleft <= 0) {
          // 当倒计时结束时
          clearInterval(downloadTimer); // 停止计时器
          state.tips = "重新获取"; // 显示倒计时结束到页面上
          state.isTips = false;
        }
      }, 1000); // 每秒执行一次
    };
    const getUserInfo = async () => {
      const res = await get("/system/user/profile");
      if (res.code == 200) {
        const { data, innovative, money, postGroup } = res;
        console.log(data, "234234");
        localStorage.setItem("nickName", data.nickName);
      }
    };

    return {
      ...toRefs(state),
      switchClick,
      codeClick,
      loadWX,
      scanQRCode,
      getParams,
      loginSubmit,
      onChange,
      onkeyPress,
      focusMobile,
      blurMobile,
    };
  },
});
</script>

<style  scoped>
.keyboard {
  bottom: 0;
  width: 100%;
  padding: 20px 0;
}
.loginW {
  font-size: 22px;
  font-weight: bold;
  margin: 20px 0;
}
.loginWBox {
  margin: 0 auto;
  text-align: center;
}
.login {
}
>>> .el-input__wrapper {
  border-radius: 22px;
  padding: 1px 10px !important;
  box-shadow: -1px 0px 0 1px #dcdfe6 inset;
}

>>> .el-input__wrapper.is-focus {
  box-shadow: 0px 0px 0 1px #95d475 inset;
}
.text {
  color: #95d475;
}
>>> .el-input-group__prepend {
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  border-bottom-left-radius: 22px;
  /* box-shadow: -1px 0px 0 1px #dcdfe6; */
  border: 1px solid #dcdfe6;
  box-shadow: none;
  border-right: none;
  background: #fff;
}
>>> .el-input-group__append {
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border: 1px solid #dcdfe6;
  box-shadow: none;
  border-left: none;
  background: #fff;
  color: #95d475;
}
.loginText {
  margin-top: 20px;
}
.logoWrap {
  padding-bottom: 45px;
  text-align: center;
  margin: 0 auto;
}
img {
  width: 130px;
  height: 130px;
}
.form {
  /* width: 395px; */
  width: 40%;
  max-width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  margin: 160px auto 0;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  margin: 0 0 24px;
}
.text {
  font-size: 16px;
}

/deep/.el-input__inner {
  /* height: 48px;
  line-height: 48px; */
}

.btn {
  width: 100%;
}
.change-lang {
  position: fixed;
  right: 20px;
  top: 20px;
}
.change-lang:deep {
}
.change-lang {
  height: 24px;
}
.change-lang:hover {
  background: none;
}
.icon {
  color: #fff;
}
</style>
