<template>
  <div class="content flex-items">
    <div class="title">
      <h2 style="margin-bottom: 10px;">闽江学院</h2>
      <h2>食安溯源管理系统</h2>
    </div>
    <el-timeline>
      <template v-for="(item, index) in list" :key="index">
        <el-timeline-item
          :timestamp="item.title"
          placement="top"
          type="primary"
          size="large"
        >
          <div
            class="flex-items timeline-item"
            v-for="(v, i) in item.list"
            :key="i"
          >
            <div class="el-timeline-item__timestamp">{{ v.lable }}：</div>
            <img
              class="tiemline-item-img"
              v-if="v.isImg"
              src="https://img2.baidu.com/it/u=1825735384,2043556172&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1684515600&t=e97f056c3693419cb1fe3850dea6d409"
            />
            <div v-if="!v.isImg" class="el-timeline-item__timestamp">
              {{ v.val }}
            </div>
          </div>
        </el-timeline-item>
      </template>
    </el-timeline>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  reactive,
  ref,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  toRefs,
} from "vue";
export default defineComponent({
  setup() {
    const state = reactive({
      list: [
        {
          title: "商品信息",
          list: [
            {
              lable: "入仓照片",
              val: "https://img2.baidu.com/it/u=1825735384,2043556172&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1684515600&t=e97f056c3693419cb1fe3850dea6d409",
              isImg: true,
            },
            {
              lable: "商品名",
              val: "土豆",
            },
            {
              lable: "商品类别",
              val: "蔬菜",
            },
            {
              lable: "商品单位",
              val: "斤",
            },
          ],
        },
        {
          title: "供应商",
          list: [
            {
              lable: "配送单位",
              val: "土豆好吃有限公司",
            },
            {
              lable: "配送单位执照",
              val: "KU789313",
            },
            {
              lable: "生产厂家",
              val: "土豆好吃有限公司",
            },
            {
              lable: " 营业执照号",
              val: "JH1298833",
            },
          ],
        },
        {
          title: "检测信息",
          list: [
            {
              lable: "检测日期",
              val: "2023年3月12日",
            },
            {
              lable: "检测结果",
              val: "合格",
            },
            {
              lable: "监测单位",
              val: "土豆安全检测研究院",
            },
          ],
        },
      ],
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style scoped>
.content {
  /* padding-left: 30px; */
  flex-direction: column;
  width: 50%;
}
.title {
  font-size: 30px;
  font-weight: 600;
  margin: 20px 0;
  text-align: center;
}
>>> .el-timeline-item__timestamp {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}
.timeline-item {
  margin: 20px 0;
}
.tiemline-item-img {
  width: 120px;
}
.flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-between-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-around-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flex-items {
  display: flex;
  align-items: center;
}
</style>