import request from '../utils/request'


export function getHomeList(query) {
  return request({
    url: 'goods/list',
    method: 'get',
    params: query
  })
}


export function get(url, query) {
  return request({
    url: url,
    method: 'get',
    params: query
  })
}

export function post(url, param = {}) {
  console.log(param,'param');
  return request({
    url: url,
    method: 'post',
    data:param,
    headers:{
      'content-type': 'application/json',
    } 
  })
}
export function postFun(url,param){
  return request({
    url: url,
    method: 'post',
    headers: {
      isToken: false
    },
    data:param,
  })
}
export function postF(url, param = {}) {
  console.log(param,'param');
  return request({
    url: url,
    method: 'post',
    data:param
  })
}

export function upload(url,flie,){

  // const formData = new FormData();

  return request.post(url, flie, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

}





