
import request from '../utils/request'
// 入仓列表
export function scaleTodaylist(todayTime) {
    return request({
        url: `system/deliveryRecord/queryScaleTodaylist/${todayTime}`,
        method: 'get',
    })
}
// 列表(pc、手机端)
export function warehouseRecordlist(query) {
    return request({
        url: `system/addWarehouseRecord/mobile/list`,
        method: 'get',
        params: query
    })
}
export function warehouseScalelist(query) {
    return request({
        url: `system/addWarehouseRecord/addScale/list`,
        method: 'get',
        params: query
    })
}
// 获取二维码
export function qrCode(query) {
    return request({
        url: `system/QRCode/queryDominUrl`,
        method: 'get',
        params: query
    })
}

// 入仓

export function addscale(param) {
    return request({
        url: `system/addWarehouseRecord/addscale`,
        method: 'post',
        data:param,
    })
}