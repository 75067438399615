import { reactive } from 'vue'
import index from '../views/index.vue'
import { createRouter, createWebHashHistory } from "vue-router";
import { getToken } from '../utils/auth';
import AddMaterial from '../views/WarehouseManagement/AddMaterial.vue' //入仓
import OutMaterial from '../views/WarehouseManagement/OutMaterial.vue' //出仓
import Login from '../views/Login.vue'//登录
import Clock from '../views/Clock.vue' //锁屏
import Withdrawal from '../views/WarehouseManagement/Withdrawal.vue' //退仓
import processScale from '../views/processScale/index.vue' //加工秤端
import processScreen from '../views/processScreen/index.vue' //加工大屏
import managementSystem from '../views/managementSystem.vue' //溯源码
// 出回餐
import returnMeal from '../views/returnMeal.vue'//出回餐首页
import scaleReduce from '../views/returnMeal/scaleReduce.vue'//出餐
import quitMeal from '../views/returnMeal/quitMeal.vue'//回餐
import { post, get, postFun } from "../api/requestUtil";

// // 入仓
// import Entry from "@/views/warehouse/entry.vue"
// // 出仓
// import OutWarehouse from "@/views/warehouse/outWarehouse.vue"
// // 加工
// import Machining from "@/views/warehouse/machining.vue"


const routes = [
    { path: '/', component: index, name: 'index' },
    { path: '/returnMeal', component: returnMeal, name: 'returnMeal' },
    { path: "/AddMaterial", component: AddMaterial, name: 'AddMaterial' },
    { path: "/OutMaterial", component: OutMaterial, name: 'OutMaterial' },
    { path: "/Login", component: Login, name: 'Login' },
    { path: "/Clock", component: Clock, name: 'Clock' },
    { path: "/Withdrawal", component: Withdrawal, name: 'Withdrawal' },
    { path: "/processScale", component: processScale, name: 'processScale' },
    { path: "/processScreen", component: processScreen, name: 'processScreen' },
    { path: "/managementSystem", component: managementSystem, name: 'managementSystem' },
    { path: "/scaleReduce", component: scaleReduce, name: 'scaleReduce' },
    { path: "/quitMeal", component: quitMeal, name: 'quitMeal' },
    // { path: "/AddMaterial", component: Entry, name: 'AddMaterial' },
    // { path: "/Entry", component: Entry, name: 'Entry' },
    // { path: "/OutWarehouse", component: OutWarehouse, name: 'OutWarehouse' },
    // { path: "/Machining", component: Machining, name: 'Machining' },

]


const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})
// return
router.beforeEach(async (to, from, next) => {
    const roles = localStorage.getItem('roles')
    const userId = localStorage.getItem('userId')
    if (to.path === "/Login") {

        next()
    } else {

        var token = getToken()
        if (token) {
            // if (roles || userId) {
            //     try {
            //         const res = await get("/system/user/profile");
            //         const { data, innovative, money, postGroup } = res;
            //         localStorage.setItem("nickName", data.nickName);
            //         next()

            //     } catch (error) {
            //         next()
            //     }

            // } else {
            //     next()
            // }
            next()

            // var store = mainStore()
            // if(!store.userInfo){
            //     router.replace("/Login")//有token, 但没有userInfo，也要跳登录页面
            // }else{
        } else {
            //没有token，跳到登录
            router.replace("/Login")
        }

    }
})




// console.log("router里面")
export { router }