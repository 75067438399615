<template>
  <div style="padding-bottom:40px">
    <div class="login form">
      <div class="logoWrap" ref="login">
        <img src="../assets/login/logo.png" />
        <div class="loginText">
          {{ !params||!params.type ||params.type== 0 ? "设置一个锁屏密码" : `欢迎你，${mobile}` }}
        </div>
      </div>
      <div class="keyBox">
        <span style="margin-bottom: 10px">{{
          isLocalPasw
            ? "密码输入错误"
            : password.length == 4
            ? "最多仅支持4位数字"
            : "请输入4位数字密码"
        }}</span>
        <el-input
          class="text"
          size="large"
          readonly
          placeholder="请输入4位数字密码"
          show-password
          v-model="password"
        ></el-input>

        <div
          v-for="(item, index) in keys"
          :key="index"
          class="keyText"
          @click="keyClick(item)"
          :class="item.length != 1 ? 'keyPad' : ''"
        >
          {{ item }}
        </div>
      </div>
      <div class="loginBut">
        <el-button size="large" @click="closeBtnCLick">退出当前账号</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  toRefs,
  ref,
  computed,
  onMounted,
  watch,
  nextTick,
} from "vue";
import XKeyboard from "./XKeyboard.vue";
import { useRouter, useRoute } from "vue-router";
import { setToken } from "../utils/auth";

export default defineComponent({
  components: { XKeyboard },
  name: "login",

  setup() {
    const { proxy } = getCurrentInstance(); // 可以把ctx当成vue2中的this
    const router = useRouter();
    const keybo = ref(null);
    const login = ref(null);
    const state = reactive({
      password: "",
      pasList: [],
      isLocalPasw: false,
      confirmBut: false,
      target: null,
      keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, "确定", 0, "删除"],
      left: 0,
      top: 0,
      input_height: 0,
      params: {},
      height: innerHeight,
      width: innerWidth,
      mobile: null,

    });

    //获取url地址后的参数
    function getParams(url) {
      const urlObject = new URL(url);
      const params = {};
      for (const [key, value] of urlObject.searchParams.entries()) {
        params[key] = value;
      }
      return params;
    }

 
    state.mobile = localStorage.getItem("mobile");
    onMounted(() => {
      state.params = history.state.params
    });
    state.isLocalPasw = computed(() => {
      if (!state.confirmBut) return false;
      return (
        state.password &&
        window.atob(localStorage.getItem("password")) &&
        window.atob(localStorage.getItem("password")) !== state.password
      );
    });

    const focuss = (event) => {
      nextTick(() => {
        console.log(keybo, login);
      });
      let input_height = event.target.offsetHeight;
      let left = event.target.offsetLeft;
      let top = event.target.offsetTop;
      // 调用键盘组件的方法，传过去input的left、top、height
      // 以及(浏览器窗口高宽)、event.target(当前点击的input)
      //  keyboard.value.focuss(left, top, input_height, state.height, state.width, event.target);
    };
    const keyClick = (value) => {
      console.log(value,'2342342');
      state.confirmBut = false;

      if (/^([0-9]{1})$/g.test(value)) {
        if (state.password.length >= 4) return;
        state.password += value;
      } else if (value == "确定") {
        // 保存锁屏密码
        if (state.password.length < 4) return;
        state.confirmBut = true;
        const roles = localStorage.getItem("roles");
        if (!state.params||state.params.type == 0) {
          // 加密
          let atob = window.btoa(state.password);
          localStorage.setItem("password", atob);
          if (roles.includes("warehouse_scale")) {
            router.push("/");
          } else {
            router.push("/returnMeal");
          }
        } else if (
          window.atob(localStorage.getItem("password")) == state.password
        ) {
          if (roles.includes("warehouse_scale")) {
            router.push("/");
          } else {
            router.push("/returnMeal");
          }
        }
      } else {
        // 删除键清空input对象内容
        state.password = state.password.slice(0, state.password.length - 1);
      }
    };
    function closeBtnCLick() {
      setToken("");
      localStorage.setItem("roles", "");
        localStorage.setItem("userId", "");
        localStorage.setItem("nickName", "");
        localStorage.setItem("userName", "");
        localStorage.setItem("mobile", "");
      router.push("/login");
    }
    return {
      ...toRefs(state),
      focuss,
      keyClick,
      closeBtnCLick,
      getParams
    };
  },
});
</script>


<style  scoped>
.loginBut {
  width: 45%;
  margin: auto;
  text-align: right;
}
.keyBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 22%;
  margin: 0 auto;
}
</style>

<style lang="scss" scoped>
.keyText {
  width: 32.33%;
  padding: 40px 0;
  border: 1px solid #dcdee2;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}
.keyPad {
  font-weight: 300;
}
// 外框位置对照浏览器窗口大小
#XKeyBoard {
  position: fixed;
  border: 0.1vw solid #dcdee2;
  user-select: none;
  color: #515a6e;
  border-radius: 2px;
  background: white;
  z-index: 100;
  // 顶部居中
  .x-top {
    width: 100%;
    height: 11%;
    display: flex;
    justify-content: center;
    // 所有div横向居中
    div {
      display: flex;
      align-items: center;
      img {
        width: 2vw;
        height: 2vw;
      }
    }
    // 标题区向左对齐
    div:nth-child(1) {
      justify-content: flex-start;
      width: 6vw;
    }
    // 空白区占据宽度
    div:nth-child(2) {
      width: 12vw;
    }
    // 关闭按钮区右对齐
    div:nth-child(3) {
      width: 2vw;
      justify-content: flex-end;
      cursor: pointer;
    }
    // 所有按钮触发效果
    div:active {
      opacity: 0.6;
      background: rgb(90, 89, 89);
    }
  }
  //  数字区宽高、盒子、框线
  .x-num {
    width: 100%;
    height: 22.1%;
    display: flex;
    border-top: 1px solid #dcdee2;
    // 所有按钮居中，改变鼠标样式
    div {
      width: 33.33%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    // 按钮触发效果
    div:active {
      background: rgb(90, 89, 89);
      color: white;
    }
  }
  .x-num > div:nth-child(3n + 1),
  .x-num > div:nth-child(3n + 2) {
    border-right: 1px solid #dcdee2;
  }
}
</style>
  
<style  scoped>
.text {
  margin: 0 3px 10px;
}
.loginW {
  font-size: 22px;
  font-weight: bold;
  margin: 20px 0;
}
.loginWBox {
  margin: 0 auto;
  text-align: center;
}
.loginText {
  margin-top: 10px;
  font-size: 30px;
  box-sizing: border-box;
}
.logoWrap {
  padding-bottom: 20px;
  text-align: center;
  margin: 0 auto;
}
img {
  width: 130px;
  height: 130px;
}
.form {
  /* width: 395px; */
  max-width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  margin: 15px auto 0;
}

.title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  margin: 0 0 24px;
}
.text {
  font-size: 16px;
}
</style>
