import { dictList } from '../api/dict'

export function dicts(arr, obj) {
    arr.forEach(item => {
        dictList({
            pageNum: 1,
            pageSize: 500,
            dictType: item
        }).then(res => {
            obj[item] = res.rows
        })
    });
    console.log(obj);
    return obj
}