
<template>
  <div>
    <div class="header">
      <div class="flexItems titleBox cont">
        <el-button
          type="success"
          class="confirm-button-class"
          size="large"
          @click="closeBtnCLick"
          >返回</el-button
        >
        <h1 class="title">仓库出仓</h1>
      </div>

      <div class="flexItems tabList cont" v-if="tabList.length">
        <div
          v-for="(item, index) in tabList"
          :class="activeName == index ? 'tabSelect' : ''"
          @click="tabClick(index)"
          class="tabItem"
          :key="item.id"
        >
          <div>{{ item.receiptorUserName }}</div>
          <!-- {{ dictsFilter("purchase_type", item.purchaseType) }}
          <div v-if="item.purchaseType && item.invoicesCode"></div>
          {{ item.invoicesCode }} -->
        </div>
      </div>
    </div>
    <!-- 定位空间 -->
    <div class="headerH"></div>
    <div class="cont" v-if="tabList.length">
      <div
        v-for="(item, index) in purchaseOrderList"
        :key="index"
        style="padding-bottom: 10px"
      >
        <div class="contTitle">{{ item.supplierCcCode }}</div>
        <el-divider style="margin: 10px 0" />
        <template v-for="(v, i) in item.warehouseReduceRecordInfoList" :key="i">
          <el-card
            :shadow="v.shadow || ''"
            class="tradeList"
            @click="cardClick(v, i, index)"
          >
            <!--             v-if="v.unit != 5" -->
            <div class="flexItems">
              <img
                class="listImg"
                :src="
                  v.goodsImg ||
                  'https://img0.baidu.com/it/u=2404799835,4177816153&fm=253&fmt=auto&app=138&f=JPEG?w=515&h=500'
                "
                alt=""
              />
              <div class="trader">
                <div class="tradeCont">
                  <span class="tradeName">{{ v.goodsName }}</span>
                  <!-- v.reduceWeight -->
                  <span class="tradeName"
                    >{{ GetNumByUnit(v.orderNo, "g", "kg") }} /kg</span
                  >
                  <span class="tradeName" style="margin-left: 30px"
                    >实际重量</span
                  >
                  <!-- v.practicalWeight -->
                  <span
                    class="tradeName"
                    v-if=" v.scaleStatus == 1"
                    >{{ GetNumByUnit(v.reduceWeight, "g", "kg") }}/kg
                  </span>
                  <span class="tradeName" v-else
                    >{{ v.reduceWeight || 0 }}/kg</span
                  >
                </div>

                <div class="tradeName" style="text-indent: 15px; height: 23px">
                  <!-- {{ goodesUnit(v) }} -->
                  {{
                    v.specFullName && !weightUntList.includes(v.unit)
                      ? `(${v.specFullName})`
                      : ""
                  }}
                </div>
                <div class="tradeCont flexItems" style="margin-top: 20px">
                  <span class="tradePrice"
                    >出仓来源：
                    <!-- {{
                      dictsFilter("warehouse_type", v.warehouseType)
                    }}- -->
                    {{ v.batchNo || "" }}</span
                  >
                </div>
              </div>
              <div class="flexItems">
                <div>照片：</div>
                <TakePhotoImg
                  @photoImgClick="photoClick($event, v)"
                  :row="v"
                  ref="PhotoImg"
                  @photoColse="photoColse(v)"
                ></TakePhotoImg>
                <el-button
                  :type="
                    v.scaleStatus == 1
                      ? 'info'
                      : 'success'
                  "
                  :disabled="
                     v.scaleStatus == 1 ||
                    v.butLoad
                      ? true
                      : false
                  "
                  size="large"
                  @click="warehousing(v, index, i, '1')"
                  style="margin-left: 20px; font-size: 30px; padding: 30px"
                  >{{
                     v.scaleStatus == 1
                      ? "已交货"
                      : "出仓"
                  }}</el-button
                >
              </div>
            </div>
          </el-card>
          <children-order ref="childrenOrder" v-if="false" />
          <withdrawal
            :row="v"
            ref="withdrawa"
            v-if="v.isdrawalBut"
          ></withdrawal>
          <!-- v-show="v.isWithdrawalBut" -->
        </template>
      </div>
    </div>
    <el-empty description="暂无数据" v-else :image-size="200"></el-empty>
  </div>
</template>
<script setup>
import {
  getCurrentInstance,
  nextTick,
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
} from "vue";

import { parseTime } from "../../utils/ruoyi.js";
import { accMul, accSub, accDiv } from "../../utils/count";
import { dicts } from "../../utils/getDict";
import {
  reduceWarehouseList,
  addscale,
  initScale,
} from "../../api/outMaterial.js";
import { useRouter } from "vue-router";
import TakePhotoImg from "../../components/TakePhotoImg.vue";
import withdrawal from "./Withdrawal.vue";
import childrenOrder from "./childrenOrder.vue";
import { uploadStaffPic } from "./dataURLtoFile.js";
import { getNowFormatDate, getTomorrowDate } from "../../utils/time";
import { GetNumByUnit, toFixedDigit } from "../../utils/uni.js";
defineProps({
  msg: String,
});
const router = useRouter();
const { proxy } = getCurrentInstance();
let activeName = ref(1);
var purchaseOrderList = ref([]);

let tabList = ref([
  { label: "加工班组A", aio: "CC2022100113", id: 1 },
  { label: "加工班组A", aio: "CC2022100113", id: 2 },
  { label: "加工班组A", aio: "CC2022100113", id: 3 },
  { label: "加工班组A", aio: "CC2022100113", id: 4 },
  { label: "加工班组A", aio: "CC2022100113", id: 5 },
  { label: "加工班组A", aio: "CC2022100113", id: 6 },
  { label: "加工班组A", aio: "CC2022100113", id: 7 },
]);
var formData = reactive({
  purchaseOrder: 1,
  procureW: "100",
  supplier: "四川夸克餐饮管理有限公司",
  weigth: 0.5,
  pName: "空心菜",
});
// 计重单位字典表的value
let weightUntList = ref(["0", "1", "2", "13", "14"]);
let withdrawa = ref();
let isWithdrawalBut = ref();
const dictObj = ref({}); //字典
let setTiem = ref(null);
let range = ref(0);
let offsetState = ref(0);

onMounted(async () => {
  getInitScale();
  getList();
  getWeight();
  // clearInterval(setTiem);
});
// 卸载之前
onBeforeUnmount(() => {
  // 清除时间函数
  clearInterval(setTiem);
});
// 卸载完成
onUnmounted(() => {});
//获取字典值
const dictsFun = async () => {
  dictObj.value = await dicts(
    ["unit_type", "goods_type", "warehouse_type", "purchase_type", "no_unit"],
    dictObj.value
  );
};
dictsFun();
// 加工称初始化
const getInitScale = () => {
  initScale().then((res) => {
    range.value = res.data.offset;
    offsetState.value = res.data.offsetState;
  });
};
// 截取规格
const unitSplic = (unitName) => {
  if (!unitName) return "";
  // 过滤字符串中的数字
  const str = unitName.replace(/\d+/g, "");
  let arr = str.split("*") || [];
  // if (arr.length > 1) return arr[arr.length - 2];
  return arr[0] || "";
};

// 获取列表
let supplierList = [];
const getList = (str = 0, type = "", row = {}) => {
  const date = new Date();
  let beginTime = parseTime(getNowFormatDate(date), "{y}-{m}-{d}");
  let endTime = parseTime(
    getNowFormatDate(getTomorrowDate(date)),
    "{y}-{m}-{d}"
  );

  const params = {
    pageNum: 1,
    pageSize: 2000,
    params: {
      beginTime: beginTime + " 00:00:00",
      endTime: endTime + " 23:59:59",
    },
    status: 0,
  };

  reduceWarehouseList(params)
    .then((res) => {
      const list = res.rows || [];
      const supplierTabList = list.filter(
        (item, index) =>
          list.findIndex((o) => o.receiptorUserId == item.receiptorUserId) ==
          index
      );
      tabList.value = supplierTabList;
      supplierList = dataFile(res.rows) || [];
      tabClick(str, row, type);
    })
    .catch((err) => {
      tabList.value = [];
    });
};
const dataFile = (data) => {
  data.forEach((item) => {
    item.warehouseReduceRecordInfoList =
      item.warehouseReduceRecordInfoList.filter((v) => {
        const dictsVal = dictsFilter("goods_type", v.goodsType);
        v.addImage = v.reduceImge || v.addImage;
        // 过滤掉调料类并且第一单位不是计重单位的商品
        let unitNames=['斤','kg','g']
        let isUnit = !unitNames.includes(unitSplic(v.specFullName))
        let isFig=isUnit&&v.goodsType==2

        return  !isFig&&v.minimumUnit != 13 && v.minimumUnit != 14;
      }) || [];
  });
  let dataArr =
    data.filter(
      (item) =>
        item.warehouseReduceRecordInfoList &&
        item.warehouseReduceRecordInfoList.length !== 0
    ) || [];

  return dataArr;
};
// 字典值过滤当前值
const dictsFilter = (dictsType, val) => {
  if (JSON.stringify(dictObj.value) == "{}") return;
  const dict = dictObj.value;
  if (!dict[dictsType] || dict[dictsType].length == 0) return "";
  const valObj = dict[dictsType].filter((item) => item.dictValue == val)[0];
  return (valObj && valObj.dictLabel) || "";
};
// 点击tab 事件
const tabClick = (index, row = {}, type) => {
  activeName.value = index;
  // 获取点击的领料人id
  const receiptorUserId = tabList.value[index].receiptorUserId;
  // 过滤出对应的商品
  purchaseOrderList.value = supplierList.filter(
    (item) => item.receiptorUserId == receiptorUserId
  );
  return;
  purchaseOrderList.value = [];
  const tabItem = tabList.value[index];

  // const row = tabItem.warehouseReduceRecordInfoList[0] || null;
  if (
    tabItem.warehouseReduceRecordInfoList &&
    tabItem.warehouseReduceRecordInfoList.length
  ) {
    if (!row) {
      tabItem.warehouseAddRecordInfoList[0].shadow = "always";
    }
    curttClick(tabItem.warehouseReduceRecordInfoList, row, type);
  }

  purchaseOrderList.value = tabItem.warehouseReduceRecordInfoList || [];
};

const curttClick = (data = [], row = {}, type = "") => {
  // 获取下标
  const i = data.findIndex((o) => o.reduceInfoId == row.reduceInfoId);
  data.forEach((item) => {
    item.addImage = item.reduceImge || item.addImage;
    item.isWithdrawalBut = item.scaleStatus == 1
        ? true
        : false;

    item.personnelUrl = item.personnelurl || item.personnelUrl;
    item.shadow = item.reduceInfoId == row.reduceInfoId ? "always" : "";
  });
  if (!type) {
    data[0].shadow = "always";
  }
  purchaseOrderList.value = data || [];

  if (type && i !== -1) {
    openList(row, i, 2);
  }
};
// 返回首页
function closeBtnCLick() {
  router.push("/");
}
// 选择当前需要秤重数据
const cardClick = (row, i, parentIdex) => {
  // 清空退仓列表的时间函数
  row.butLoad = false;
  purchaseOrderList.value.forEach((item) => {
    if (
      item.warehouseReduceRecordInfoList.findIndex(
        (o) => item.isdrawalBut == true
      ) != -1
    ) {
      withdrawa.value[index].clearItem();
    }
  });
  // console.log(data,'23423');

  // index 获取选择之前的数据 清空退仓列表的时间函数
  // if (index !== -1) {
  //   withdrawa.value[index].clearItem();
  // }
  // 判断是否为出仓的数据
  const isfig =row.scaleStatus == 1 ? true : false;

  purchaseOrderList.value.forEach((o) => {
    o.warehouseReduceRecordInfoList.forEach((item) => {
      item.shadow = item.reduceInfoId == row.reduceInfoId ? "always" : "";
      item.isdrawalBut = false;
      if (item.reduceInfoId == row.reduceInfoId) {
        item.isdrawalBut =
          row.shadow == "always" && row.isWithdrawalBut ? true : false;
        // 判断重量和图片是否有值，如果就不用显示称重数字
        item.reduceWeight = isfig ? item.reduceWeight : formData.weigth;
        // item.price = accMul(item.num, item.addWeigth);
      }
    });
  });

  if (row.shadow == "always" && isfig) {
    openList(row, i);
  } else {
    row.butLoad = false;
    withdrawa.value[i].clearItem();
  }
  // if (row.practicalWeight && row.addImage) return;
};
// 单价change事件
const numberChange = (e, row) => {
  if (  row.scaleStatus == 1) return;
  purchaseOrderList.value.forEach((o) => {
    o.warehouseReduceRecordInfoList.forEach((item) => {
      if (item.reduceInfoId == row.reduceInfoId) {
        item.reduceWeight = formData.weigth || 0;
        // item.price = accMul(item.num, item.addWeigth);
      }
    });
  });
};
// 当前选择的数据
const selectionObj = () => {
  let obj = {};
  let dat = purchaseOrderList.value.filter((item) => {
    const index = item.warehouseReduceRecordInfoList.findIndex(
      (o) => o.shadow === "always"
    );
    if (index != -1) {
      obj = item.warehouseReduceRecordInfoList[index];
      return item.warehouseReduceRecordInfoList[index];
    }
  });
  return obj;
};
// 重量改变事件
const weightSelection = (res) => {
  const row = selectionObj();
  // purchaseOrderList.value.filter((item) => item.shadow == "always")[0] || {};

  if (
    ( row.scaleStatus == 1) ||
    row.isWithdrawalBut
  )
    return;

  // row.practicalWeight = formData.weigth;
  if (JSON.stringify(row) !== "{}") {
    purchaseOrderList.value.forEach((o) => {
      o.warehouseReduceRecordInfoList.forEach((item) => {
        if (row.reduceInfoId == item.reduceInfoId) {
          item.reduceWeight = formData.weigth;
        }
      });
    });
    numberChange(row.addPrice, row);
  }
};
// 拍照确认照片
const photoClick = (data = {}, row) => {
  console.log(data, "23423423");
  row.loading = true;
  row.addImage = (data && data.url) || "";
  row.reduceImge = (data && data.url) || "";
  row.loading = false;
};
// 清空拍照图片
const photoColse = (row) => {
  if ( row.scaleStatus == 1) return;
  purchaseOrderList.value.forEach((o) => {
    o.warehouseReduceRecordInfoList.forEach((item) => {
      if (item.reduceInfoId == row.reduceInfoId) {
        item.addImage = "";
        row.personnelUrl = "";
        row.reduceImge =""
      }
    });
  });
};
// 出仓
const warehousing = (row, parentIdex, i, type) => {
  if (type == 2) {
    router.push("/Withdrawal");
    return;
  }
  const date = new Date();
  const practicalWeight = GetNumByUnit(row.reduceWeight, "kg", "g");
  row.butLoad = true;
  console.log("来；了");
  // 拍照
  if (!row.addImage) {
    automaticPhoto(row, parentIdex);
    return;
  }
  console.log("来；了2");

  if (!row.addImage && !row.reduceWeight) {
    proxy.$message.warning("请检查重量(重量不能为0)及图片是否完善");
    row.butLoad = false;
    return;
  }

  // 必须判断偏离值
  if (offsetState.value == "1") {
    getRange(row);
  } else {
    addWarehousing(row);
  }
};
// 自动调用拍照
const automaticPhoto = async (row) => {
  // row.butLoad = false;
  if (!proxy.$refs.PhotoImg) return (row.butLoad = false);
  // 获取当前确定自动拍照数据的下标
  const index = proxy.$refs.PhotoImg.findIndex((o) => {
    let item = o.row;
    return (
      item.supplierCcCode == row.supplierCcCode &&
      item.goodsId == row.goodsId &&
      item.canteenGoodsSpecId == row.canteenGoodsSpecId &&
      item.scaleStatus == row.scaleStatus
    );
  });
  if (!proxy.$refs.PhotoImg[index] && index != -1) return (row.butLoad = false);

  const data = (await proxy.$refs.PhotoImg[index].photograph(1)) || {};
  // row.isFig=true

  setTimeout(() => {
    row.addImage = data.url || "";
    row.reduceImge = data.url || "";
    if (!row.addImage) return;
    warehousing(row, index);
  }, 200);

  // row.isFig=false
};
// 启用绝对值进行比较
const getRange = (row) => {
  const reduceWeight = GetNumByUnit(row.reduceWeight, "kg", "g");
  // 取 偏离值的绝对值
  const num = accSub(row.reduceWeight - reduceWeight);
  const numbe = range.value * -1;
  if (num >= numbe && num <= range.value) {
    addWarehousing(row);
  } else {
    let message =
      num < range.value ? "多了！！！" : num > numbe ? "少了！！！" : "";
    proxy.$message.warning(message);
    row.butLoad = false;
  }
};
// 出仓请求
const addWarehousing = (row) => {
  proxy.$messageBox
    .confirm(`是否确认出库?`, "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning",
      buttonSize: "large",
      cancelButtonClass: "cancel-button-class",
      customClass: "custom-class-text",
      confirmButtonClass: "confirm-button-class el-button--success",
    })
    .then(() => {
      const userId = localStorage.getItem("userId");
      let obj = {
        scaleStatus: "1",
        addWeight: GetNumByUnit(row.reduceWeight, "kg", "g"),
        reduceWeight: GetNumByUnit(row.reduceWeight, "kg", "g"),
        practicalUnit: "0",
        // receiptorUserId: userId,
        reduceUserId: userId,
      };
      let data = Object.assign({}, row, obj);
      addscale(data)
        .then((res) => {
          if (res.code == 200) {
            row.isWithdrawalBut = true;
            proxy.$message.success(res.msg);
            row.scaleStatus = "1";
            row.reduceWeight=obj.reduceWeight
            row.reduceImge=row.addImage
            row.shadow = '';
            // getList(activeName.value, 2, row);
          } else {
            row.scaleStatus = "0";
            row.isWithdrawalBut = false;
            proxy.$message.error(res.msg);
          }
          row.butLoad = false;

          // openList(row, i, 2);
        })
        .catch((err) => {
          row.scaleStatus = "0";
          row.isWithdrawalBut = false;
          row.butLoad = false;

          // proxy.$error(res.msg);
        });
    })
    .catch(() => {
      proxy.$message("取消");
      row.scaleStatus = "0";
      row.butLoad = false;
    });
};

// 打开退仓列表
const openList = (row, i, type) => {
  if (type) {
    purchaseOrderList.value.forEach((o) => {
      o.warehouseReduceRecordInfoList.forEach((item) => {
        item.isWithdrawalBut = item.reduceInfoId == row.reduceInfoId;
        item.isdrawalBut = item.reduceInfoId == row.reduceInfoId;
      });
    });
  }
  row.isdrawalBut = true;
  setTimeout(() => {
    console.log(withdrawa.value, i, "withdrawa.value[i]");
    const ind = withdrawa.value && withdrawa.value.length > 1 ? 0 : i;
    withdrawa.value[ind].withdrawalSelect(row, i);
  }, 300);
};
// 获取数字
const getFloat = function (v) {
  if (typeof v == "string") {
    const str = v.split(/[\t\r\f\n\s]*/g).join("");
    var regEx = /[^\d|^\.|^\-]/g;
    const num = str.replace(regEx, "");
    return parseFloat(num);
  }
};
// 每200 刷新秤端值
const getWeight = () => {
  setTiem = setInterval(() => {
    const weight = getFloat(script.getWindowName());
    console.log(weight, "weight");
    if (formData.weigth !== weight) {
      formData.weigth = weight;
      weightSelection(weight);
    }
  }, 200);
  return setTiem;
};

// 接受秤重返回值
var kg = ref(0);
let arr = ref([]);

// 字典值
const dictLable = (dict, dictValue = "") => {
  const data = dictObj.value;
  let dictVal = null;
  if (data[dict]) {
    dictVal = data[dict].filter((item) => item.dictValue === dictValue)[0];
  }
  return (dictVal && dictVal.dictLabel) || "";
};

// 商品数量和计数单位拼接
const goodesUnit = (row) => {
  let unitType =
    row.noUnit == 10
      ? "j"
      : row.noUnit == 11 || row.noUnit == 13
      ? "g"
      : row.noUnit == 12
      ? "L"
      : row.noUnit == 14
      ? "kg"
      : "";

  let val = row.cubage;
  if (unitType) {
    val = GetNumByUnit(val, "g", unitType);
  }
  const noUnit = dictLable("no_unit", row.noUnit);
  const unitLable = dictLable("unit_type", row.unit);
  const sizeLable = dictLable("unit_type", row.sizeUnit);
  let noUnitLable = noUnit.substr(1, noUnit.length) || "";
  if (!noUnitLable) return "";
  if (row.unit == 3) {
    const sizeUnit = sizeLable.replace("/", `*${row.minSize || ""}`);
    return `(${val}${noUnitLable}${sizeUnit})`;
  }
  return `(${val}${noUnitLable}${unitLable})`;
};
</script>

<style scoped>
>>> .el-card.is-always-shadow {
  box-shadow: 0px -5px 25px rgba(0.5, 0, 0, 0.5);
}
.header {
  position: fixed;
  background: #fff;
  z-index: 999;
  width: 100%;
}
.headerH {
  height: 160px;
}
.tabList {
  border: 1px solid #e4e7ed;
  overflow-x: auto;
}
.tabItem {
  border-right: 1px solid #e4e7ed;
  padding: 6px;
  min-width: 20%;
  font-weight: bold;
  color: #424141;
  text-align: center;
  border-radius: none;
  min-height: 45px;
  line-height: 45px;
  font-size: 25px;
}
.tabSelect {
  background: #95d475;
  border-right-color: #95d475;
  color: #fff;
}
>>> .el-input__wrapper.is-focus {
  box-shadow: 0px 0px 0 1px #95d475 inset;
}
>>> .el-input-number__decrease:hover,
>>> .el-input-number__increase:hover {
  color: #95d475;
}
.cont {
  position: relative;
}
/* 屏幕小于1280px */
@media screen and (max-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/*  屏幕小于1520或大于1280 */
@media screen and (max-width: 1520px) and (min-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/* 屏幕大于1520px */
@media screen and (min-width: 1520px) {
  .cont {
    width: 75%;
    margin: 0 auto;
  }
}
.tradeList {
  margin: 6px 0 10px;
  border-radius: 20px;
}
.trader {
  flex: 1;
  /* text-align: center; */
}
.tabBox {
  padding: 0 30px;
  display: flex;
  /* width: 60%; */
}
.tradeName {
  font-size: 23px;
  margin: 0 5px;
  font-weight: bold;
}
.tradePrice {
  margin: 0 5px;
}
.tradeCont {
  margin: 10px 15px;
}
.listImg {
  width: 220px;
  height: 150px;
}
.el-tabs--border-card {
  width: 100%;
  margin-right: 15px;
}
.flex {
  display: flex;
}
.flexItems {
  display: flex;
  align-items: center;
}
.wrap {
  /* position: fixed; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.titleBox {
  margin-left: 2px;
  margin: auto;
}
.title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
  text-indent: 20px;
}
.contTitle {
  font-size: 25px;
  text-indent: 20px;
}
</style>