import request from '../utils/request'
// 回餐列表
export function listScaleReturnRecord(query) {
  return request({
    url: '/system/scaleReturnRecord/list',
    method: 'get',
    params: query
  })
}
// 修改
export function editScaleReturnRecord(params = {}) {
  return request({
    url: '/system/scaleReturnRecord',
    method: 'put',
    data: params
  })
}