<!-- 出餐记录 -->
<template>
  <div>
    <div class="header">
      <div class="flexItems justifyBetween titleBox cont">
        <h1 class="title">今日菜单:</h1>
        <div class="flexItems">
          <div class="flexItems" @click="reload">
            <el-icon size="25">
              <refresh />
            </el-icon>
            <span class="butText">刷新任务</span>
          </div>
          <div class="butText" style="margin-left: 20px" @click="closeBtnCLick">
            返回
          </div>
        </div>
      </div>
    </div>
    <!-- 占位符（请勿删除） -->
    <div style="height: 70px"></div>
    <!-- 占位符 end -->
    <div class="cont" v-if="purchaseOrderList.length">
      <el-card
        :shadow="v.shadow || ''"
        class="tradeList"
        v-for="(v, i) in purchaseOrderList"
        @click="cardClick(v)"
        :key="i"
      >
        <div class="flexItems">
          <img
            class="listImg"
            :src="
              v.canteenDishesImg ||
              'https://img0.baidu.com/it/u=2404799835,4177816153&fm=253&fmt=auto&app=138&f=JPEG?w=515&h=500'
            "
            alt=""
          />
          <div class="tradeCont trader">
            <div>
              <span class="tradeName">{{ v.canteenDishesName }}</span>
              <span class="tradeName">{{ v.reduceNoW || 0 }}/kg</span>
            </div>
            <div style="margin-top: 15px">
              <span class="tradeName">出餐重量</span>
              <span class="tradeName">{{ v.addweight || 0 }}/kg</span>
            </div>
          </div>
          <div class="flexItems">
            <div>照片：</div>
            <TakePhotoImg
              ref="PhotoImg"
              :row="v"
              @photoImgClick="photoClick($event, v)"
              @photoColse="photoColse(v)"
            ></TakePhotoImg>
            <el-button
              :type="
                v.addweight && v.addimage && v.scaleStatus == 1
                  ? 'info'
                  : 'success'
              "
              :disabled="
                (v.addweight && v.addimage && v.scaleStatus == 1) || v.butLoad
              "
              @click="warehousing(v, i)"
              size="large"
              style="margin-left: 20px; font-size: 25px"
              >{{
                v.addweight && v.addimage && v.scaleStatus == 1
                  ? "已出餐"
                  : "出 餐"
              }}</el-button
            >
            <el-button
              v-if="v.stayWeight"
              :type="'success'"
              @click="print(v, i)"
              size="large"
              style="margin-left: 20px; font-size: 25px"
              >打印留样</el-button
            >
          </div>
        </div>
      </el-card>
    </div>
    <el-empty description="暂无数据" v-else :image-size="200"></el-empty>

    <!-- 弹框 -->
    <el-dialog
      v-model="centerDialogVisible"
      width="30%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div style="font-size: 36px">请留样</div>
      <div style="font-size: 32px; margin-top: 20px">
        留样重量：{{ stayWeightS }}
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            style="font-size: 20px; padding: 20px"
            size="medium"
            @click="deit"
            >确 定</el-button
          >
          <el-button
            style="font-size: 20px; padding: 20px"
            size="medium"
            @click="centerDialogVisible = false"
            >取消</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  reactive,
  toRefs,
  ref,
  computed,
  onMounted,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import TakePhotoImg from "../../components/TakePhotoImg.vue";
import { getNowFormatDate, getTomorrowDate } from "../../utils/time";
import { uploadStaffPic } from "../WarehouseManagement/dataURLtoFile";
import { dicts } from "../../utils/getDict";
import { parseTime } from "../../utils/ruoyi.js";
import { GetNumByUnit } from "../../utils/uni.js";
import { accMul, accSub } from "../../utils/count";
import {
  listReduceRecord,
  editReduceRecord,
  initScale,
} from "../../api/scaleReduce";
import { setHoursTime } from "../../utils/time";
export default defineComponent({
  components: { TakePhotoImg },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      purchaseOrderList: [],
      setTiem: null,
      dictObj: {},
      formData: {
        weigth: 1,
      },
      centerDialogVisible: false,
      selectionRow: {},
      range: {},
      stayWeight: null,
      stayWeightS: null,
    });
    onMounted(async () => {
      state.dictObj = await dicts(["unit_type", "goods_type"], state.dictObj);
      // 10秒钟刷新界面
      //   state.refreshData = setInterval(() => {
      //     reload();
      //     const date = parseTime(new Date());
      //     console.log("===》", date);
      //   }, 1000 * 60 * 10);
      getInitScale();
      getList();
      getWeight();
    });
    onBeforeUnmount(() => {
      console.log("卸载之前");
      clearInterval(state.setTiem);
    });
    state.stayWeightS = computed(() => {
      console.log(state.stayWeight, "state.stayWeight");
      let stayWeigh = accSub(state.stayWeight, state.formData.weigth);
      return stayWeigh;
    });

    // 刷新界面
    const reload = () => {
      //   router.push("/");
      const date = parseTime(new Date());

      console.log(2342, "测温", date);
      window.location.reload();
    };
    // 获取列表
    const getList = () => {
      const date = new Date();
      let beginTime = parseTime(getNowFormatDate(date), "{y}-{m}-{d}");
      let endTime = parseTime(
        getNowFormatDate(getTomorrowDate(date)),
        "{y}-{m}-{d}"
      );

      const params = {
        pageNum: 1,
        pageSize: 2000,
        params: {
          beginTime: beginTime + " 00:00:00",
          endTime: endTime + " 23:59:59",
        },
        status: 0,
      };
      listReduceRecord(params)
        .then((res) => {
          if (res.code == 200) {
            const data = res.rows;
            if (data.length) {
              data.forEach((item) => {
                item.addImage = item.addimage;
                item.reduceNoW = GetNumByUnit(item.reduceNo, "g", "kg");

                if (item.addweight && item.addImage && item.scaleStatus == 1) {
                  // item.addweight = GetNumByUnit(item.addweight, "j", "kg");
                  item.addweight = GetNumByUnit(item.addweight, "j", "kg");
                  item.isFig = true;
                } else {
                  item.addweight = item.addweight
                    ? GetNumByUnit(item.addweight, "g", "kg")
                    : 0;
                }
              });
            }
            state.purchaseOrderList = data;
          }
        })
        .catch((err) => {});
    };
    // 初始化 留样称重范围
    const getInitScale = () => {
      initScale().then((res) => {
        if (res.code == 200) {
          state.range = res.data;
          console.log(res.data, "inite");
        }
      });
    };
    // 返回
    function closeBtnCLick() {
      router.push("/returnMeal");
    }
    // 选择当前需要秤重数据
    const cardClick = (row) => {
      console.log(row, "2423");
      if (state.centerDialogVisible) return;
      if (row.addweight && row.addImage && row.scaleStatus == 1) return;

      state.purchaseOrderList.forEach((item) => {
        item.shadow = item.reduceId == row.reduceId ? "always" : "";
        if (item.reduceId == row.reduceId) {
          item.addweight = state.formData.weigth || 0;
          //   warehouseUrl += row.addInfoId;
        }
      });
    };
    // 拍照确认照片
    const photoClick = (data = {}, row, i) => {
      row.loading = true;
      row.addImage = data&&data.url||"";
      row.loading = false;
    };
    // 清空拍照图片
    const photoColse = (row) => {
      if (row.addweight && row.addImage && row.scaleStatus == 1) return;
      state.purchaseOrderList.forEach((item) => {
        if (item.reduceId == row.reduceId) {
          item.addImage = "";
          row.addimage = "";
          row.personnelUrl = "";
        }
      });
    };
    // 出餐
    const warehousing = (row, i) => {
      row.butLoad = true;
      // 拍照
      if (!row.addImage) {
        automaticPhoto(row, i);
        return;
      }

      const addweight = GetNumByUnit(row.addweight, "kg", "g");
      const canteenId = localStorage.getItem("canteenId") || "";
      const time = new Date();
      if (row.addImage && row.addweight) {
        state.stayWeight = row.addweight;
        row.isSample = "0";
        const obj = {
          reduceUserId: localStorage.getItem("userId"),
          scaleStatus: "1",
          // practicalUnit: "0",
          practicalUnit: row.unit,
          // unit: "0",
          addimage: row.addimage ? row.addimage : row.addImage,
          addweight: addweight,
          canteenId: canteenId,
        };
        row.stayTime = parseTime(time, "{y}-{m}-{d} {h}:{i}");
        const data = Object.assign({}, row, obj);
        state.selectionRow = data;
        if (state.range.offsetState == 1) {
          getRange(data);
        } else {
          row.butLoad = false;
          state.centerDialogVisible = state.range.stayState == 1 ? true : false;
          if (!state.centerDialogVisible) {
            confirm(data);
          }
        }
      } else {
        proxy.$message.warning("请检查重量(重量不能为0)及图片是否完善");
        row.butLoad = false;
      }
    };
    // 自动调用拍照
    const automaticPhoto = async (row, i) => {
      if (!proxy.$refs.PhotoImg || !proxy.$refs.PhotoImg[i])
        return (row.butLoad = false);
      const data = (await proxy.$refs.PhotoImg[i].photograph(1)) || {};
      // row.isFig=true
      setTimeout(() => {
        row.addImage = data.url || "";
        if (!row.addImage) return;
        warehousing(row, i);
      }, 200);
    };
    // 启用绝对值进行比较
    const getRange = (row) => {
      // 取 偏离值的绝对值
      const num = accSub(row.reduceNo - row.addweight);
      const numbe = state.range.offset * -1;
      if (num >= numbe && num <= state.range.offset) {
        state.selectionRow = row;
        state.centerDialogVisible = state.range.stayState == 1 ? true : false;
        if (state.range.stayState == 0) {
          confirm(state.selectionRow);
        }
      } else {
        row.butLoad = false;

        let message =
          num < state.range.offset
            ? "多了！！！"
            : num > numbe
            ? "少了！！！"
            : "";
        proxy.$message.warning(message);
      }
    };

    // 确认留样
    const deit = () => {
      const stayWeight = Number(GetNumByUnit(state.formData.weigth, "kg", "g"));
      const data = state.selectionRow;
      const obj = {
        stayWeight: accSub(Number(data.addweight), stayWeight) || 0,
      };
      const row = Object.assign({}, data, obj);
      const max = state.range.maxStay;
      const min = state.range.miniStay;
      console.log(stayWeight, obj.stayWeight, data, "stayWeight");
      if (obj.stayWeight < min) {
        row.butLoad = false;
        return proxy.$message.warning("留样重量过少！！！");
      }
      if (obj.stayWeight >= max) {
        row.butLoad = false;
        return proxy.$message.warning("留样重量过多！！！");
      }
      confirm(row);
    };
    // 出餐请求
    const confirm = (row) => {
      editReduceRecord(row)
        .then((res) => {
          if (res.code == 200) {
            getList();
            if (state.range.stayState == 1) {
              print(row);
              state.centerDialogVisible = false;
            }
          } else {
            row.scaleStatus = "0";
            proxy.$message(res.smg);
            if (state.range.stayState == 1) {
              state.centerDialogVisible = false;
            }
          }
          row.butLoad = false;
        })
        .catch((err) => {
          row.scaleStatus = "0";
          row.butLoad = false;
        });
    };
    //打印
    const print = (row) => {
      const userName = localStorage.getItem("nickName");
      let orderName =
        row.orderType == 0
          ? "早餐"
          : row.orderType == 1
          ? "中餐"
          : row.orderType == 2
          ? "晚餐"
          : " ";
      const orderHoursTime = setHoursTime(row.stayTime, 25);
      const dat = script.printPagesN(
        orderName,
        row.canteenDishesName || " ",
        row.stayTime || " ",
        userName || " ",
        orderHoursTime || ""
      );
    };
    // 重量改变事件
    const weightSelection = (res) => {
      if (state.centerDialogVisible) return;
      const row =
        state.purchaseOrderList.filter((item) => item.shadow == "always")[0] ||
        {};
      if (row.addweight && row.addImage && row.scaleStatus == 1) return;
      console.log(2342342);
      row.addweight = state.formData.weigth;
      if (JSON.stringify(row) !== "{}") {
        state.purchaseOrderList.forEach((item) => {
          if (row.reduceId == item.reduceId) {
            item.addweight = state.formData.weigth;
          }
        });
        console.log(row, state.formData);
      }
    };
    // 接受秤重返回值
    // 获取数字
    const getFloat = function (v) {
      if (typeof v == "string") {
        const str = v.split(/[\t\r\f\n\s]*/g).join("");
        var regEx = /[^\d|^\.|^\-]/g;
        const num = str.replace(regEx, "");
        return parseFloat(num);
      }
    };
    // 每200 刷新秤端值
    const getWeight = () => {
      state.setTiem = setInterval(() => {
        const weight = getFloat(script.getWindowName());
        if (state.formData.weigth !== weight) {
          state.formData.weigth = weight;
          weightSelection();
        }
      }, 200);
      return state.setTiem;
    };
    const dictsFilter = (dictsType, val) => {
      if (JSON.stringify(state.dictObj) == "{}") return;
      const dict = state.dictObj;

      const valObj = dict[dictsType].filter((item) => item.dictValue == val)[0];
      return (valObj && valObj.dictLabel) || "";
    };

    return {
      ...toRefs(state),
      reload,
      closeBtnCLick,
      cardClick,
      photoClick,
      getList,
      photoColse,
      warehousing,
      getWeight,
      getFloat,
      dictsFilter,
      print,
      deit,
    };
  },
});
</script>

<style  scoped>
>>> .el-card.is-always-shadow {
  box-shadow: 0px -5px 25px rgba(0.5, 0, 0, 0.5);
}
.trader {
  flex: 1;
  /* text-align: center; */
}
.tradeName {
  font-size: 23px;
  margin: 0 5px;
  font-weight: bold;
}
.tradeCont {
  margin: 10px 15px;
}
.listImg {
  width: 220px;
  height: 150px;
}
.tradeList {
  margin: 6px 0 10px;
  border-radius: 20px;
}
.header {
  position: fixed;
  background: #fff;
  z-index: 1;
  width: 100%;
}
.butText {
  font-size: 23px;
}
.titleBox {
  margin-left: 2px;
  margin: auto;
}
.title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
  /* text-indent: 20px; */
}
.cont {
  position: relative;
}
/* 屏幕小于1280px */
@media screen and (max-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/*  屏幕小于1520或大于1280 */
@media screen and (max-width: 1520px) and (min-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/* 屏幕大于1520px */
@media screen and (min-width: 1520px) {
  .cont {
    width: 75%;
    margin: 0 auto;
  }
}
</style>