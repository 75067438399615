import { upload } from '../api/requestUtil.js'


// base64转blob方法：
export const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = window.atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};
// blob转file方法：
export const blobToFile = (blob, fileName) => {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
};

// base64直接转file方法：
export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    var blob = dataURLtoBlob(dataurl);
    return blobToFile(blob, filename);
};
// base64直接转file方法：
export const base64ToFile = (urlData, fileName = '.png') => {
    let arr = urlData.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bytes = window.atob(arr[1]); // 解码base64
    let n = bytes.length;
    let ia = new Uint8Array(n);
    while (n--) {
        ia[n] = bytes.charCodeAt(n);
    }
    return new File([ia], fileName, { type: mime });
}


//拍照上传
export const uploadStaffPic = (url, imgBaseCode, picFile) => {
    //静态base64编码
    /*this.file = this.dataURLtoFile(this.imgBaseCode, this.picFile);
    imgBaseCode:'',//用来接收base64编码
    */
    let file = base64ToFile(imgBaseCode, picFile);
    let formData = new FormData(); //将文件以file:(binary)格式进行上传
    formData.append("file", file);
    // data.isCompress=false
    formData.append("isCompress", false);

    return new Promise((resolve, reject) => {
        upload(url, formData).then((res) => {
            if (res.code === 200 && res.data) {
                resolve(res.data)
            } else {
                reject()
            }
        }).catch(err => {
            reject(err)
        });
    })
};