<template>
  <div>
    <div class="header">
      <div class="flexItems justifyBetween titleBox cont">
        <h1 class="title">今日菜单:</h1>
        <div style="font-weight: bold; margin-left: 5px" @click="outLogin">
          退出当前账号
        </div>
      </div>
    </div>
    <!-- 定位空间 -->
    <div class="headerH"></div>
    <div class="cont">
      <el-card
        :shadow="v.shadow"
        class="tradeList"
        v-for="(v, i) in purchaseOrderList"
        @click="cardClick(v)"
        :key="i"
      >
        <div class="flexItems">
          <img class="listImg" :src="v.goodsImg||
                'https://img0.baidu.com/it/u=2404799835,4177816153&fm=253&fmt=auto&app=138&f=JPEG?w=515&h=500'" alt="" />
          <div class="trader flexItems">
            <div class="tradeCont">
              <div class="tradeName">
                {{ v.pName }}：{{ v.procureW }}
                <i
                  class="iconfont icon-a-1tongyong-2Icontubiao-Line-Check-Circle iconSize"
                ></i>
              </div>
              <div class="tradeName">交货：{{ v.procureW }}</div>
            </div>
            <div class="tradeCont">
              <div v-for="o in v.list" :key="o">
                <span class="tradeName tradeItem"
                  >{{ o.pName }}：{{ o.procureW }}
                </span>
                <span class="tradeName tradeItem">交货：{{ o.procureW }}</span>
                <i
                  class="iconfont icon-a-1tongyong-2Icontubiao-Line-Check-Circle iconSize"
                  :class="
                    o.isfig
                      ? 'icon-a-1tongyong-2Icontubiao-Line-Question-Circle-Line'
                      : ''
                  "
                ></i>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
  <script>
import {
  getCurrentInstance,
  reactive,
  ref,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  toRefs,
} from "vue";
import { parseTime } from "../../utils/ruoyi.js";
import { useRouter } from "vue-router";
import { setToken } from "../../utils/auth";

export default defineComponent({
  name: "Withdrawal",
  setup() {
    const { proxy } = getCurrentInstance(); // 可以把ctx当成vue2中的this
    const router = useRouter();

    const state = reactive({
      purchaseOrderList: [
        {
          pName: "土豆炖牛肉",
          shadow: "always",
          procureW: "100g",
          delivery: "100g",
          img: "https://paimgcdn.baidu.com/9B15ACF76EE5CFB1?src=http%3A%2F%2Fms.bdimg.com%2Fdsp-image%2F7219354556.jpg&rz=urar_2_968_600&v=0",
          supplier: "四川夸克餐饮管理有限公司",
          id: 1,
          list: [
            {
              pName: "土豆",
              procureW: "70g",
              delivery: "70g",
            },
            {
              pName: "牛肉",
              procureW: "30g",
              delivery: "30g",
            },
          ],
        },
        {
          pName: "土豆",
          procureW: "500g",
          shadow: "",
          img: "https://img2.baidu.com/it/u=1825735384,2043556172&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1684515600&t=e97f056c3693419cb1fe3850dea6d409",
          supplier: "蜀海(北京)供应链管理有限责任公司",
          id: 2,
          list: [
            {
              pName: "土豆",
              procureW: "500g",
              delivery: "70g",
            },
          ],
        },
        {
          pName: "仔姜",
          procureW: "30",
          shadow: "",
          supplier: "北京云杉世界信息技术有限公司",
          id: 3,
          list: [
            {
              pName: "土豆",
              procureW: "70g",
              delivery: "70g",
            },
            {
              pName: "牛肉",
              procureW: "30g",
              delivery: "30g",
            },
          ],
        },
        {
          pName: "黄瓜",
          procureW: "50g",
          shadow: "",
          supplier: "上海田野农产品配送集团有限公司",
          id: 4,
        },
        {
          pName: "娃娃菜",
          shadow: "",
          procureW: "550g",
          supplier: "北京三快科技有限公司",
          id: 5,
        },
        {
          pName: "西红柿",
          shadow: "",
          procureW: "50g",
          img: "	https://img1.baidu.com/it/u=1606833012,690599066&f…sec=1684515600&t=dd61d56a05d09ede708b517dd9ab85dc",
          supplier: "乐禾食品集团股份有限公司",
          id: 6,
        },
        {
          pName: "青椒",
          shadow: "",
          procureW: "60g",
          supplier: "杭州小农网络科技有限公司",
          id: 7,
        },
        {
          pName: "玉米",
          shadow: "",
          procureW: "40g",
          supplier: "好生活农产品集团有限公司",
          id: 8,
        },
        {
          pName: "大排",
          procureW: "400g",
          shadow: "",
          supplier: "望家欢农产品集团有限公司",
          id: 10,
        },
        {
          pName: "圣农单冻琵琶腿",
          procureW: "500g",
          shadow: "",
          supplier: "永辉彩食鲜发展有限公司",
          id: 11,
        },
        {
          pName: "小西红柿",
          shadow: "",
          procureW: "340g",
          sup: "深圳市农产品集团股份有限公司",
          id: 12,
        },
      ],
      activeName: 1,
      formData: {
        purchaseOrder: 1,
        procureW: "100",
        supplier: "四川夸克餐饮管理有限公司",
        weigth: null,
        pName: "空心菜",
      },
      kg: 0,
      arr: [],
      refreshData: null,
    });
    function outLogin() {
      setToken("");
      router.push("/login");
    }
    return {
      ...toRefs(state),
      outLogin,
    };
  },
});
</script>
  <style scoped>
.header {
  position: fixed;
  background: #fff;
  z-index: 1;
  width: 100%;
}
.iconSize {
  font-size: 25px;
  font-weight: bold;
}
.icon-a-1tongyong-2Icontubiao-Line-Question-Circle-Line {
  color: red;
}
.justifyBetween {
  justify-content: space-between;
}
.butText {
  font-size: 23px;
}
.headerH {
  height: 70px;
}
.tradeItem {
  display: inline-block;
}
.tradeList {
  margin: 6px 0 10px;
  border-radius: 20px;
}
.trader {
  flex: 1;
  /* text-align: center; */
}

.tradeName {
  font-size: 23px;
  margin: 10px 5px;
  font-weight: bold;
}

.tradeCont {
  margin: 10px 15px;
  flex: 1;
}
.listImg {
  width: 220px;
  height: 150px;
}

.flexItems {
  display: flex;
  align-items: center;
}

.titleBox {
  margin-left: 2px;
  margin: auto;
}
.title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
  /* text-indent: 20px; */
}
.cont {
  position: relative;
}
@media screen and (max-width: 1920px) and (min-width: 1430px) {

  .tradeName {
    font-size: 46px;
  }
}
@media screen and (max-width: 1430px) and (min-width: 1381px) {

.tradeName {
  font-size: 40px;
}
}
@media screen and (max-width: 1379px) and (min-width: 1281px) {

  .tradeName {
    font-size: 35px;
  }
}
/* 屏幕小于1280px */
@media screen  and (max-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
  .tradeName {
    font-size: 32px;
  }
}
/*  屏幕小于1520或大于1280 */
@media screen and (max-width: 1520px) and (min-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/* 屏幕大于1520px */
@media screen and (min-width: 1520px) {
  .cont {
    width: 75%;
    margin: 0 auto;
  }
}
</style>