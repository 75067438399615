<template>
  <div>
    <div class="cont">
      <el-card
        :shadow="v.shadow"
        class="tradeList"
        v-for="(v, i) in purchaseOrderList"
        :key="i"
      >
        <div class="flexItems">
          <div class="trader">
            <div class="tradeCont">
              <span class="tradeName">实际重量</span>
              <span class="tradeName">{{ v.addWeight || 0 }}/kg</span>
            </div>
            <div class="tradeCont flexItems" style="margin-top: 20px">
              <span class="tradePrice">退仓目的地：</span>
              <!-- <span class="tradePrice">全部退仓</span> -->
              <el-radio-group v-model="v.warehouseType" :disabled="v.rrInfoId">
                <el-radio
                  :key="index"
                  v-for="(item, index) in dictObj.warehouse_type"
                  :label="item.dictValue"
                  :name="item.dictValue"
                  >{{ item.dictLabel }}</el-radio
                >
              </el-radio-group>
            </div>
          </div>
          <div class="flexItems">
            <div>照片：</div>
            <TakePhotoImg
              ref="PhotoImg"
              :row="v"
              @photoImgClick="photoClick($event, v)"
              @photoColse="photoColse(v)"
            ></TakePhotoImg>

            <el-button
              :type="v.isBut || v.rrInfoId ? 'info' : 'success'"
              size="large"
              :disabled="v.isBut || v.rrInfoId || v.butWLoad"
              @click="warehousing(v, i, '2')"
              style="margin-left: 20px; font-size: 30px; padding: 30px"
              >{{ v.isBut || v.rrInfoId ? "已退仓" : "退仓" }}</el-button
            >
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
  <script>
import {
  getCurrentInstance,
  reactive,
  ref,
  defineComponent,
  toRefs,
  onMounted,
  onBeforeUnmount,
  watch,
  onUnmounted,
  nextTick,
} from "vue";
import { parseTime } from "../../utils/ruoyi.js";
import { accMul } from "../../utils/count";
import { useRouter } from "vue-router";
import { dicts } from "../../utils/getDict";
import TakePhotoImg from "../../components/TakePhotoImg.vue";
import { uploadStaffPic } from "./dataURLtoFile.js";
import {
  returnwarehouse,
  reduceRetuenRecordInfo,
} from "../../api/outMaterial.js";
import { GetNumByUnit } from "../../utils/uni.js";
export default defineComponent({
  components: { TakePhotoImg },
  name: "Withdrawal",
  props: {
    row: {
      type: Object,
      default: () => {
        return { list: [{}] };
      },
    },
  },

  setup(props) {
    const { proxy } = getCurrentInstance(); // 可以把ctx当成vue2中的this
    const router = useRouter();
    // let PhotoImg = ref();

    const state = reactive({
      purchaseOrderList: [],
      activeName: 1,
      formData: {
        purchaseOrder: 1,
        procureW: "100",
        supplier: "四川夸克餐饮管理有限公司",
        weigth: null,
        pName: "空心菜",
      },

      dictObj: {}, //字典
      setTiem: null,
      data: {},
    });

    // 当前选择
    const withdrawalSelect = (row = {}, i) => {
      clearInterval(state.setTiem);
      state.data = row;
      const data = JSON.parse(JSON.stringify(row)) || {};
      getList(data);
    };
    const getList = (row = {}) => {
      reduceRetuenRecordInfo({
        invoicesCode: state.data.invoicesCode,
        returnGoodsId: state.data.goodsId,
        canteenGoodsSpecId: state.data.canteenGoodsSpecId,
      }).then((res) => {
        if (res && res.rows.length) {
          res.rows[0].shadow = "always";
          res.rows.forEach((item) => {
            item.addImage = item.returnImage;
            item.addWeight = GetNumByUnit(item.returnWeight, "g", "kg");
          });
          state.purchaseOrderList = res.rows;
        } else {
          let item = {
            shadow: "always",
            warehouseType: row.warehouseType,
            warehouseId: row.warehouseId,
            reduceInfoId: row.reduceInfoId,
            reduceUserId: localStorage.getItem("userId"),
            unit: row.unit,
            addWeight: state.formData.weigth,
          };
          let obj = Object.assign(row, item);
          delete obj.addImage;
          row.list = [obj];
          state.purchaseOrderList = row.list || [];
          console.log(state.purchaseOrderList, "state.purchaseOrderList", row);
          nextTick(() => {
            getWeight();
          });
        }
      });
    };
    // 获取字典
    const getDict = async () => {
      state.dictObj = await dicts(["warehouse_type"], state.dictObj);
    };
    // 返回首页
    const closeBtnCLick = () => {
      router.push("/");
    };
    // 选择当前需要秤重数据
    const cardClick = (row) => {
      return;
      state.purchaseOrderList.forEach((item) => {
        item.shadow = item.id == row.id ? "always" : "";
        if (item.id == row.id) {
          item.weigth = state.formData.weigth || 0;
          item.price = accMul(item.num, item.weigth);
        }
      });
    };

    // 重量改变事件
    const weightSelection = (res) => {
      // const row =
      //   state.purchaseOrderList.filter((item) => item.shadow == "always")[0] ||
      //   {};
      // if (JSON.stringify(row) !== "{}") {
      state.purchaseOrderList.forEach((item) => {
        if (item.shadow == "always") {
          item.addWeight = state.isBut ? item.addWeight : state.formData.weigth;
        }
      });
      //   numberChange(row.num, row);
      // }
    };
    // 拍照确认照片
    const photoClick = (data, row) => {
      row.loading = true;
      row.addImage = (data && data.url) || "";
      row.loading = false;
    };
    // 清空拍照图片
    const photoColse = (row) => {
      state.purchaseOrderList.forEach((item) => {
        if (item.reduceInfoId == row.reduceInfoId) {
          item.addImage = "";
          row.personnelUrl = "";
        }
      });
    };
    // 出仓

    const warehousing = (row, i) => {
      row.butWLoad = true;
      // 拍照
      if (!row.addImage) {
        automaticPhoto(row, i);
        return;
      }
      if (row.addImage && row.addWeight) {
        const addWeight = GetNumByUnit(row.addWeight, "kg", "g");
        
        if (state.data.reduceWeight < addWeight) {
        // if (state.data.practicalWeight < addWeight) {
          row.butWLoad = false;
          return proxy.$message("多了");
        }
        proxy.$messageBox
          .confirm(`是否确认退仓?`, "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
            buttonSize: "large",
            cancelButtonClass: "cancel-button-class",
            customClass: "custom-class-text",
            confirmButtonClass: "confirm-button-class el-button--success",
          })
          .then(() => {
            const obj = {
              returnMaterialId: localStorage.getItem("userId"),
              scaleStatus: "1",
              practicalUnit: "0",
              // unit: "0",
              addWeight: GetNumByUnit(row.addWeight, "kg", "g"),
            };
            // PhotoImg.value[i].photograph();
            const data = Object.assign({}, row, obj);
            // row.addWeight = GetNumByUnit(row.addWeight, "kg", "g");
            delete data.list;
            returnwarehouse(data)
              .then((res) => {
                if (res.code == 200) {
                  row.isBut = true;
                  proxy.$message.success("退仓成功");
                  getList(state.data);
                } else {
                  proxy.$message.success(res.msg);
                }
                row.butWLoad = false;
                clearInterval(state.setTiem);
              })
              .catch((err) => {
                row.scaleStatus = "0";
                row.loading = false;
                row.butWLoad = false;
              });
          })
          .catch(() => {
            proxy.$message("取消");
            row.scaleStatus = "0";
            row.butWLoad = false;
          });
      } else {
        proxy.$message.warning("请检查重量(重量不能为0)及图片是否完善");
        row.butWLoad = false;
      }
    };
    // 自动调用拍照
    const automaticPhoto =async (row, i) => {
      if (!proxy.$refs.PhotoImg || !proxy.$refs.PhotoImg[i])
        return (row.butWLoad = false);
        const data = (await proxy.$refs.PhotoImg[i].photograph(1)) || {};
      // row.isFig=true
      setTimeout(() => {
    row.addImage = data.url || "";
        if (!row.addImage) return;
        warehousing(row, i);
        // row.isFig=false
      }, 2500);
    };
    // 接受秤重返回值
    // 获取数字
    const getFloat = function (v) {
      if (typeof v == "string") {
        const str = v.split(/[\t\r\f\n\s]*/g).join("");
        var regEx = /[^\d|^\.|^\-]/g;
        const num = str.replace(regEx, "");
        return parseFloat(num);
      }
    };

    // 每200 刷新秤端值
    const getWeight = () => {
      state.setTiem = setInterval(() => {
        const weight = getFloat(script.getWindowName());
        if (state.formData.weigth !== weight) {
          state.formData.weigth = weight;
          weightSelection(weight);
        }
      }, 200);
    };
    // 清除时间函数
    const clearItem = () => {
      clearInterval(state.setTiem);
    };
    onMounted(() => {
      getDict();
    });

    // 卸载之后
    onUnmounted(() => {
      // 清除时间函数
      clearInterval(state.setTiem);
    });

    return {
      ...toRefs(state),
      getDict,
      closeBtnCLick,
      cardClick,
      weightSelection,
      photoClick,
      photoColse,
      warehousing,
      withdrawalSelect,
      getWeight,
      clearItem,
      automaticPhoto,
    };
  },
});
</script>

<style scoped>
>>> .el-card.is-always-shadow {
  box-shadow: 0px -5px 25px rgba(0.5, 0, 0, 0.5);
}
.header {
  position: fixed;
  background: #fff;
  z-index: 1;
  width: 100%;
}
.headerH {
  height: 140px;
}
.tabList {
  border: 1px solid #e4e7ed;
}
.tabItem {
  border-right: 1px solid #e4e7ed;
  padding: 15px;
  font-size: 25px;
  font-weight: bold;
  color: #424141;
  border-radius: none;
}
.tabSelect {
  background: #95d475;
  border-right-color: #95d475;
  color: #fff;
}
>>> .el-input__wrapper.is-focus {
  box-shadow: 0px 0px 0 1px #95d475 inset;
}
>>> .el-input-number__decrease:hover,
>>> .el-input-number__increase:hover {
  color: #95d475;
}
.cont {
  position: relative;
}
/* 屏幕小于1280px */
@media screen and (max-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/*  屏幕小于1520或大于1280 */
@media screen and (max-width: 1520px) and (min-width: 1280px) {
  .cont {
    width: 90%;
    margin: 0 auto;
  }
}
/* 屏幕大于1520px */
@media screen and (min-width: 1520px) {
  .cont {
    width: 75%;
    margin: 0 auto;
  }
}
.tradeList {
  margin: 6px 0 10px;
  border-radius: 20px;
}
.trader {
  flex: 1;
  /* text-align: center; */
}
.tabBox {
  padding: 0 30px;
  display: flex;
  /* width: 60%; */
}
.tradeName {
  font-size: 23px;
  margin: 0 5px;
  font-weight: bold;
}
.tradePrice {
  margin: 0 5px;
}
.tradeCont {
  margin: 10px 15px;
}
.listImg {
  width: 220px;
  height: 150px;
}
.el-tabs--border-card {
  width: 100%;
  margin-right: 15px;
}
.flex {
  display: flex;
}
.flexItems {
  display: flex;
  align-items: center;
}
.wrap {
  /* position: fixed; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}

.titleBox {
  margin-left: 2px;
  margin: auto;
}
.title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0;
  text-indent: 20px;
}
</style>