import request from '../utils/request'
// 加工列表
export function listProcessOrderRecord(query) {
    return request({
      url: '/system/processOrderRecord/scaleOrderRecordlist',
      method: 'get',
      params: query
    })
  }

  
// 修改加工订单
export function updateProcessOrderRecord(data) {
    return request({
      url: '/system/processOrderRecord',
      method: 'put',
      data: data
    })
  }
  