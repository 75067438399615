
import request from '../utils/request'
// 出仓列表
export function reduceWarehouseList(query = {}) {
    return request({
        url: `system/reduceWarehouseRecord/mobile/list`,
        method: 'get',
        params: query
    })
}



// 出仓

export function addscale(param) {
    return request({
        url: `system/reduceWarehouseRecord/reduceRecordcale`,
        method: 'post',
        data: param,
    })
}

// 退仓
export function returnwarehouse(param) {
    return request({
        url: `system/reduceRetuenRecord/returnWarehouse`,
        method: 'post',
        data: param,
    })
}

// 出仓退货详情
export function reduceRetuenRecordInfo(query) {
    return request({
        url: `system/reduceRetuenRecordInfo/list`,
        method: 'get',
        params: query

    })
}

// 加工称初始化 
export function initScale(query={}) {
    return request({
        url: `system/Scale/initScale`,
        method: 'get',
        params: query

    })
}